import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { AuthContext } from '../contexts/AuthContext';
import jwt_decode from "jwt-decode";

const AuthRoute = ({ component: Component, ...rest }) => {
    const authContext = useContext(AuthContext);
    let FBToken = localStorage.getItem('tokens');
    // let decodedToken = jwt_decode(authContext.authTokens);
    let decodedToken = jwt_decode(FBToken);
    let isValid = decodedToken.exp*1000 > Date.now();
    return (
        <Route {...rest} render={(props) => isValid ? (<Component {...props} />) : (<Redirect to={{ pathname: '/', state: {referer: props.location} }}/>)} />
    );
}

export default AuthRoute
