import React, { useState, useEffect, useContext } from 'react';
import { 
    makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import JobDetailComponent from './JobDetail';
import Navbar from '../../../layouts/DashboardLayout/NavBar';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '4rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
    container: {
        width: '100%'
    }
}));

function JobDetail({match: { params: {id}}}) {
    console.log('JOB ID', id)
    const classes = useStyles();
    // preset job variable for iterable
    let job = {appliedCandidates: [], assignedRecruiter: '', city: '', clientName: '', createdBy: '', createdOn: '', employmentType: '', industry:'', interviewedCandidates: [], jobDescription: '', jobId: '', jobStatus: '', jobTitle: '', modifiedBy: '', numberOfHired: null, numberOfPositions: null, priority: null, salary :'', searchKeywords: [], skillset:'', state: '', submittedCandidates:[], targetDate: '',workExperience:'',zipCode:'' }
    let [jobDetails, setJobDetails] = useState(job);
    useEffect(() => {
        axios.get(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/jobs/${id}`, {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
            }
        })
        .then(res => {
            console.log('resdata ', res.data);
            setJobDetails({...res.data, appliedCandidates: [...res.data.appliedCandidates], interviewedCandidates: [...res.data.interviewedCandidates]});
        })
        .catch(err => {
            console.error(err);
        });
    }, [])
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <JobDetailComponent jobDetails={jobDetails} />
            </div>
        </div> 
    )
}

export default JobDetail
