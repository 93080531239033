import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
//import { makeStyles } from '@material-ui/core/styles';
import { 
    Container,
    withStyles,
    makeStyles,
    Box,
    Card,
    Grid,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Button,
    TableRow 
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import Rating from '@material-ui/lab/Rating';
import { AuthContext } from '../../../contexts/AuthContext';
import Navbar from '../../../layouts/DashboardLayout/NavBar';
import { GridOverlay, DataGrid, ColDef } from '@material-ui/data-grid';
// import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios';
import CandidateToolbar from '../CandidateToolbar';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '4rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
    container: {
        marginBottom: 40
    },
    table: {
        width: '100%',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: '14px',
    },
    gridWrapper: {
        width: '100%'
    },
    tblheadFirstchild: {
        paddingLeft: 45,
        fontWeight: 'bold',
        fontSize: '14px',
    }
}));

const StyledButton = withStyles({
    root: {
      //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      borderRadius: 3,
      border: 0,
      //color: 'white',
      height: 48,
      padding: '0 30px',
      //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
      textTransform: 'capitalize',
    },
})(Button);

function CandidateList() {
    //const classes = useStyles();
    //const { userAuth } = useContext(AuthContext);
    
    // let expiredIn = localStorage.getItem('expiredIn');
    // console.log(expiredIn)
    const [loading, setLoading] = useState(true)
    const history = useHistory();
    const classes = useStyles();
    const { userAuth } = useContext(AuthContext);
    const [candidates, setCandidates] = useState([]);
    const [filter, setFilter] = useState('')
    console.log('userAuth from Candidates', userAuth)
    function formatPhoneNumber(number) {
        console.log('TYPE ', typeof(number))
        let newNumber;
        if (number.length > 10) {
            newNumber = '(' + number.split('-')[0] + ') ' + number.split('-')[1] + '-' + number.split('-')[2];
        } else {
            newNumber =  '(' + number.slice(0,3) + ') ' + number.slice(3,6) + '-' + number.slice(6,10);
        }
        return newNumber
    }
    function formatDate(input) {
        let newDate = input.substring(0,10);
        console.log('NEW DATE ', newDate)
        return input.substring(0,10)
    }
    let arr = [];
    const columns = [
        { field: 'candidateName', width: 250, renderHeader: () => (<strong style={{fontSize: 17}}>Candidate Name</strong>)},
        { field: 'jobApplied', width: 380, renderHeader: () => (<strong style={{fontSize: 17}}>Job Applied</strong>)},
        { field: 'email', width: 300, renderHeader: () => (<strong style={{fontSize: 17}}>Email</strong>)},
        { field: 'phone', width: 170, renderHeader: () => (<strong style={{fontSize: 17}}>Phone</strong>)},
        { field: 'appliedOn', width: 150, renderHeader: () => (<strong style={{fontSize: 17}}>Applied On</strong>)},
        { field: 'rating', headerName: 'Rating', width: 170, 
            renderHeader: () => (<strong style={{fontSize: 17}}>Rating</strong>),
            renderCell: (param) => (
                <Rating
                    size="small"
                    name="simple-controlled"
                    readOnly
                    value={param.row.rating}
                />
        )},
        { field: 'resume', width: 120,
            renderHeader: () => (<strong style={{fontSize: 17}}>Resume</strong>),
            renderCell: (param) => (
                <IconButton color="primary" aria-label="Download" href={param.row.resume}>
                    <GetAppIcon />
                </IconButton>
        )},
        { field: 'zipcode', width: 130, renderHeader: () => (<strong style={{fontSize: 17}}>Zip Code</strong>)},
        
    ];
    const [data, setData] = useState({columns: columns, rows:[]});
    const searchCandidate = (candidate) => {
        setFilter(candidate)
    }
    const handleRowClick = (candidate) => {
        console.log('CANDIDATE ', candidate)
        history.push(`/app/candidates/${candidate.data.candidateId}`);
    }  

    function CustomLoadingOverlay() {
        return (
          <GridOverlay>
            <div style={{ position: 'absolute', top: 10, width: '100%' }}>
              <LinearProgress />
            </div>
          </GridOverlay>
        );
    }

    useEffect(() => {
        axios.get('https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/candidates', {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
            }
        })
        .then(res => {
            console.log(res.data)
            let counter = 0;
            res.data.forEach(candidate => {
                arr.push({
                    candidateName:candidate.firstName + ' ' + candidate.lastName, 
                    candidateId:candidate.candidateId, email:candidate.email, 
                    phone: formatPhoneNumber(candidate.phoneNumber), 
                    rating:candidate.rating, zipcode: candidate.zipCode, 
                    jobApplied: candidate.appliedJobs[candidate.appliedJobs.length-1].jobTitle, 
                    appliedOn:formatDate(candidate.createdOn), resume:candidate.resume, 
                    id:counter++})
            })
            //setCandidates(res.data);
            setData({columns: columns, rows: [...arr]})
            setLoading(false);
        })
        .catch(err => {
            console.error(err);
        });
    }, [candidates.length])


    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <Container maxWidth={false} className={classes.container}>
                    <Grid container spacing={3} className={classes.gridWrapper}>
                        <Grid item md={12} xs={12}>
                            <CandidateToolbar className={classes.toolbar} data={data} searchCandidate={searchCandidate}/>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Card>
                                <Box height={700}>
                                    <DataGrid  
                                        components={{
                                            loadingOverlay: CustomLoadingOverlay,
                                        }} 
                                        loading ={loading}
                                        {...data} 
                                        onRowSelected={handleRowClick} pageSize={50} filterModel={{
                                            items: [{ columnField: 'candidateName', operatorValue: 'contains', value: filter },],
                                        }} 
                                    />
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
}

export default CandidateList
