import React, { useContext, useState } from 'react';
import {useForm} from 'react-hook-form';
import { Link, Redirect, useHistory, withRouter } from "react-router-dom";
import cryptoJs from 'crypto-js';
//import {  withRouter } from "react-router";

import AppIcon from '../../icons/fullIcon.png'
import {
    Grid,
    Typography,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import Typography from '@material-ui/core/Typography'
// import TextField from '@material-ui/core/TextField'
// import Button from '@material-ui/core/Button'
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
// import { AuthContext } from "../App";
// import { useHistory } from 'react-router-dom' 
// export const LoginContext = createContext();
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.white,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 160,
    },
    image: {
        width: '350px'
    },
    button: {
        marginTop: '2rem'
    },
    box : {
        marginTop: '1rem'
    }
}));

const Login = (props) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [checked, setChecked] = useState(localStorage.getItem('_u') ? true : false);
    const { setAuthTokens } = useAuth();
    const referer = props.location.state || '/app/dashboard';

    const classes = useStyles();
    // const { userAuth, updateUserAuth } = useContext(AuthContext)
    //console.log('initial Auth ', userAuth)
    const {register, handleSubmit} = useForm();

    const handleCheckbox = (event) => {
        setChecked(event.target.checked);
    };
    //console.log('history ', props.history)
    const onSubmit = (data) => {
        // axios.post('http://localhost:5001/sunshineenterpriseus/us-central1/api/login', {email: data.email, password: data.password})
        axios.post('https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/login', {email: data.email, password: data.password})
        .then(res => {
            //console.log(res.data)
            let firstInitial = res.data.name.split('')[0]
            localStorage.setItem('firstInitial', firstInitial);
            //
            if (checked === true) {
                console.log('check is true')
                localStorage.setItem('_u', cryptoJs.enc.Base64.stringify(cryptoJs.enc.Utf8.parse(data.email)))
                localStorage.setItem('s', cryptoJs.enc.Base64.stringify(cryptoJs.enc.Utf8.parse(data.password)))  
            } else {
                //alert('check is false')
                localStorage.removeItem('_u')
                localStorage.removeItem('s')  
            }
            // Set AuthContext and local storage 
            // res.data.isLoggedIn = true;
            // res.data.loginError = false;
            localStorage.setItem('name', res.data.name);
            // localStorage.setItem('FBIdToken', `Bearer ${res.data.token}`);
            // localStorage.setItem('authenticated', true);
            // localStorage.setItem('position', res.data.token.position);
            localStorage.setItem('role', res.data.role);
            // console.log('local storage ', localStorage)
            setAuthTokens(res.data.token);
            setLoggedIn(true);
            // if (isLoggedIn) {
            //     return <Redirect to={referer} />;
            // }
            props.history.push('/app/dashboard')
            //localStorage.setItem('expiredIn', Date.now() + 5000);
            //updateUserAuth( res.data );
            // if (history === undefined) {
            //     window.location.href = '/app/clients'
            // } else {
            //     history.push('/app/clients')
            // }
            //history.push('/app/clients')
        })
        .catch(err => {
            console.log(err.response);
            setIsError(true);
            //console.log('code ', err.response.data.error.code);
            if (err.response.data.email === "must be a valid email") {
                setErrorCode('Must be a valid email')
            } else if (err.response.data.error.code === "auth/wrong-password") {
                setErrorCode('The password is invalid or wrong password')
            } else if (err.response.data.error.code === "auth/user-not-found") {
                setErrorCode('User not found or wrong email address')
            } else if (err.response.data.error.code === "auth/too-many-requests") {
                setErrorCode('Your account is temperary blocked due to too many attemps, please try again later')
            } else if (err.response.data.error.code === "auth/user-disabled") {
                setErrorCode('Your account has been disabled by an administrator')
            } else {
                setErrorCode('Bad request');
            }
            //updateUserAuth ({ loginError: true });
            //history.push('/login');
        })
    }
    if (isLoggedIn) {
        props.history.push('/app/dashboard');
    }
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
            <Grid container >
                <Grid item sm/>
                <Grid item sm>
                    <img src={AppIcon} alt='sunshine enterprise icon' className={classes.image} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField 
                            id='email' 
                            name='email' 
                            type='email' 
                            label='Email' 
                            required
                            defaultValue={localStorage.getItem('_u') ? cryptoJs.enc.Base64.parse(localStorage.getItem('_u')).toString(cryptoJs.enc.Utf8) : ''}
                            inputRef={register}
                            fullWidth 
                        />
                        <Box className={classes.box}/>
                        <TextField 
                            id='password' 
                            name='password' 
                            type='password' 
                            label='Password' 
                            required
                            defaultValue={localStorage.getItem('s') ? cryptoJs.enc.Base64.parse(localStorage.getItem('s')).toString(cryptoJs.enc.Utf8) : ''}
                            inputRef={register}
                            fullWidth 
                            // error = {isError}
                            // helperText={isError ? 'Wrong password' : ''}
                        />
                        <Box className={classes.box}/>
                        {/* {userAuth === true ?  <Typography color='error'>Wrong Credential </Typography> : ''}   */}
                        <FormControlLabel
                            control={<Checkbox checked={checked} onChange={handleCheckbox} name="saveCredential" color="primary"/>}
                            label="Save username and password"
                        />
                        <Box className={classes.box}/>
                        {isError ? <><Typography fullWidth color='error'>{errorCode}</Typography><Typography color='error'>To resset your password click <Link to='/app/resetpassword' >here</Link></Typography></> : <></>}
                        <Button  className={classes.button} type='submit' variant='contained' color='primary'> Login </Button>
                        
                    </form>
                </Grid>
                <Grid item sm/>
            </Grid>
                

                
            </div>
        </div>
        
    );
}
 
export default withRouter(Login);