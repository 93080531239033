import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { AuthContext } from '../../../contexts/AuthContext'
import Navbar from '../../../layouts/DashboardLayout/NavBar';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 40,
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
}));

function ClientList() {
    const classes = useStyles();
    const { userAuth } = useContext(AuthContext);
    console.log('userAuth from Clients', userAuth)
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <Container>
                    <h1>Client Page Under Construction</h1>
                </Container>
            </div>
        </div>
    )
}

export default ClientList
