import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//import dashboard from '../pages/report/DashboardView';
import CandidateListPage from '../pages/candidate/CandidateListPage';
import DashboardPage from '../pages/report/DashboardView';
//import editCandidate from './pages/editCandidate';
import AssignmentListPage from '../pages/assignment/AssignmentListPage';
//import addAssignment from './pages/addAssignment';
// import addJob from './pages/addJob';
// import editJob from './pages/editJob';
import JobListPage from '../pages/job/JobListPage';
import ClientListPage from '../pages/client/ClientListPage';
import LoginPage from '../pages/auth/index';
import EditUserPage from '../pages/admin/UserManagement/EditUser';
//import ResetPasswordPage from '../pages/account/ResetPassword';
//import AddJobPage from '../pages/job/AddJobPage';
// import jobs from './pages/jobs';

// import job from './pages/job';
// import login from './pages/login';
import Navbar from '../layouts/DashboardLayout/NavBar';
import { AuthContext } from '../contexts/AuthContext';

const ProtectedRoute = () => {
    
    return (
        // <>

        
            <Router>
            {/* <Navbar /> */}
                <Switch>
                    <Route exact path='/' component={LoginPage} />
                    <Route exact path='/dashboard' component={DashboardPage} />
                    <Route exact path='/app/candidates' component={CandidateListPage} />
                    {/* <Route exact path="/app/candidates/:id" component={candidate} /> */}
                    {/* <Route exact path='/app/candidates/:id/edit' component={editCandidate} /> */}
                    <Route exact path='/app/assignments' component={AssignmentListPage} />
                    <Route exact path='/app/jobs' component={JobListPage} />
                    <Route exact path='/app/clients' component={ClientListPage} />
                    <Route exact path='/app/admin/usermanagement/edit/:user' component={EditUserPage} />
                    {/* <Route exact path='/app/resetpassword' component={ResetPasswordPage} /> */}
                    {/* <Route exact path='/app/add/jobs' component={AddJobPage} /> */}
                    {/* <Route exact path='/app/assignments/add' component={addAssignment} />
                    
                    <Route exact path="/app/jobs/:id" component={job} />
                    <Route exact path="/app/jobs/:id/edit" component={editJob} /> */}
                   

                </Switch>
            </Router>
        // </div>}
        // </>
        // <div>
        //     <Router>
        //     <Navbar />
        //         <Switch>
        //             <Route exact path='/' component={LoginPage} />
        //             <Route exact path='/app/dashboard' component={DashboardPage} />
        //             <Route exact path='/app/candidates' component={CandidateListPage} />
        //             {/* <Route exact path="/app/candidates/:id" component={candidate} /> */}
        //             {/* <Route exact path='/app/candidates/:id/edit' component={editCandidate} /> */}
        //             <Route exact path='/app/assignments' component={AssignmentListPage} />
        //             <Route exact path='/app/jobs' component={JobListPage} />
        //             <Route exact path='/app/clients' component={ClientListPage} />
        //             {/* <Route exact path='/app/assignments/add' component={addAssignment} />
        //             <Route exact path='/app/add/jobs' component={addJob} />
        //             <Route exact path="/app/jobs/:id" component={job} />
        //             <Route exact path="/app/jobs/:id/edit" component={editJob} /> */}
                   

        //         </Switch>
        //     </Router>
        // </div>
    );
}
 
export default ProtectedRoute;