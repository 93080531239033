import React, { useContext } from 'react'
import { useHistory } from "react-router-dom";
import { makeStyles, Grid } from '@material-ui/core';
//import { Container } from '@material-ui/core';
import { AuthContext } from '../../contexts/AuthContext';
import Navbar from '../../layouts/DashboardLayout/NavBar';
import jwt_decode from 'jwt-decode';
import { Container } from '@material-ui/core';
import UserManagement from './UserManagement';
import ContentManagement from './ContentManagement'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '5rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: '1rem'
        }
    },
    
}));

function AdminPage() {
    const history = useHistory();
    let token = localStorage.getItem('tokens');
    //let expiredToken = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUwOGI0NzM0YjYxNmE0MWFhZmE5MmNlZTVjYzg3Yjc2MmRmNjRmYTIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVnUgTmd1eWVuIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3N1bnNoaW5lZW50ZXJwcmlzZXVzIiwiYXVkIjoic3Vuc2hpbmVlbnRlcnByaXNldXMiLCJhdXRoX3RpbWUiOjE2MDk5NjYwNTgsInVzZXJfaWQiOiI2MHpBMURVTlZIVUI3cmpzaHk1ZXRpZUFNa0YzIiwic3ViIjoiNjB6QTFEVU5WSFVCN3Jqc2h5NWV0aWVBTWtGMyIsImlhdCI6MTYwOTk2NjA1OCwiZXhwIjoxNjA5OTY5NjU4LCJlbWFpbCI6Im5ndXllbmFuaGhvYW52dUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibmd1eWVuYW5oaG9hbnZ1QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.g7CDaXyOTGDH41zravTgLqcffbZEn7LqvrBYwqVxx-h1xCHSUJqP00Je4OdVmioJGamgPUOVeIknhU8H5Z8bIXlMHWDEmmnpT141aItCETo-cZIb7YcsY-MjIqpXNIsI4tzrHD40kYWKmquw7K2bUjkYeLg8S4c2qrqWLZRoPxjADWjwovdZ-2l7c7Bv8S554s2V5T_7jC_0Q4_rdt9LsXPvUFV4ko6TpHMyhgijofxlxq2yr_QzAHHXOQNHu9bDyr4l5B1OYiYLdrLiA6BFLc4T8FnY2IMl7p8XFvdtIk0Ce5zMk6SrRXCDbxQhLvJZ3ubFFC3APuzToGLJuCUUyA"
    //console.log(token);
    const classes = useStyles();
    let decodedToken = jwt_decode(token);
    //let decodedExp = jwt_decode(expiredToken);
    //console.log('EXPIRED TOKEN ? ', decodedToken.exp *1000 - Date.now())
    //console.log ('result ', (decodedToken.exp * 1000 - 3000000 - 250000 -10000) - Date.now())
    // if (decodedToken.exp * 1000 - 3000000 - 250000 - 10000< Date.now()){
    //     console.log('auth axpired')
    //     // authenticated = false
    //     history.push('/')
    // } else {
    //     // authenticated = true
    //}
    //console.log('decoded ', decodedToken);
    const { authTokens, setAuthTokens } = useContext(AuthContext);
    //console.log('authContext ', userAuth)
    console.log('userAuth from Dashboard', authTokens)
    console.log('settoken ', setAuthTokens)
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <Container flex>
                    <Grid container spacing={4}>
                        <Grid item md={5} xs={12}>
                            <UserManagement />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <ContentManagement />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
}

export default AdminPage
