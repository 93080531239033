import React, {useEffect, useContext, useState} from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';

//import { useHistory } from "react-router-dom";
//import NavBar from './layouts/DashboardLayout/NavBar';
import ProtectedRoute from './components/Router';
import { AuthContext } from './contexts/AuthContext';
import Login from './pages/auth';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//import dashboard from '../pages/report/DashboardView';
import CandidateListPage from './pages/candidate/CandidateListPage';
import CandidateDetail from './pages/candidate/CandidateDetailPage';
import EditCandidate from './pages/candidate/EditCandidatePage'
import DashboardPage from './pages/report/DashboardView';
//import editCandidate from './pages/editCandidate';
import AssignmentListPage from './pages/assignment/AssignmentListPage';
//import addAssignment from './pages/addAssignment';
// import addJob from './pages/addJob';
// import editJob from './pages/editJob';
import JobListPage from './pages/job/JobListPage';
import ClientListPage from './pages/client/ClientListPage';
import LoginPage from './pages/auth/index';
import AuthRoute from './utils/AuthRoute';
import AddJobPage from './pages/job/AddJobPage';
import JobDetailPage from './pages/job/JobDetailPage';
import EditJobPage from './pages/job/EditJobPage';
import ResetPasswordPage from './pages/account/ResetPassword';
import SettingsPage from './pages/settings';
import TextSMS from './pages/sms/ContactListPage';
import AddContactPage from './pages/sms/AddContactPage';
import ContactDetailPage from './pages/sms/ContactDetailPage';
import EditContactPage from './pages/sms/EditContactPage';
import SendGroupSMSPage from './pages/sms/SendGroupSMSPage';
import AdminPage from './pages/admin';
import UserManagementPage from './pages/admin/UserManagement';
import EditUserPage from './pages/admin/UserManagement/EditUser';
import SendGridPage from './pages/sendgrid';

function App(props) {
  const [authTokens, setAuthTokens] = useState();
  const setTokens = (data) => {
    localStorage.setItem('tokens', JSON.stringify(data));
    setAuthTokens(data);
  }
  return (
    
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens}}>
        <div className="App">
          <div className="container"> 
            <ThemeProvider theme={theme}>

            <Router>
            {/* <Navbar /> */}
                <Switch>
                    <Route exact path='/' component={LoginPage} />
                    <AuthRoute exact path='/app/dashboard' component={DashboardPage} />
                    <AuthRoute exact path='/app/candidates' component={CandidateListPage} />
                    <AuthRoute exact path="/app/candidates/:id" component={CandidateDetail} />
                    <AuthRoute exact path='/app/candidates/:id/edit' component={EditCandidate} />
                    <AuthRoute exact path='/app/assignments' component={AssignmentListPage} />
                    <AuthRoute exact path='/app/jobs' component={JobListPage} />
                    <AuthRoute exact path='/app/clients' component={ClientListPage} />
                    <AuthRoute exact path='/app/add/jobs' component={AddJobPage} />
                    <AuthRoute exact path="/app/jobs/:id" component={JobDetailPage} />
                    <AuthRoute exact path="/app/jobs/:id/edit" component={EditJobPage} />
                    <AuthRoute exact path="/app/settings" component={SettingsPage} />
                    <AuthRoute exact path="/app/sms" component={TextSMS} />
                    <AuthRoute exact path='/app/add/contacts' component={AddContactPage} />
                    <AuthRoute exact path="/app/contacts/:id" component={ContactDetailPage} />
                    <AuthRoute exact path="/app/contacts/:id/edit" component={EditContactPage} />
                    <AuthRoute exact path="/app/sendgroupsms/contacts" component={SendGroupSMSPage} />
                    <AuthRoute exact path="/app/admin" component={AdminPage} />
                    <AuthRoute exact path="/app/admin/usermanagement" component={UserManagementPage} />
                    <AuthRoute exact path="/app/admin/usermanagement/edit/:id" component={EditUserPage} />
                    <AuthRoute exact path="/app/sendgrid" component={SendGridPage} />
                    <Route exact path="/app/resetpassword" component={ResetPasswordPage} />                
                </Switch>
            </Router>
              
              
            </ThemeProvider>
          </div>
        </div>
    </AuthContext.Provider>
    
  );
}

export default App;
