import React, { useState, useEffect, useContext } from 'react';
import { 
    makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import ContactDetailComponent from './ContactDetail';
import Navbar from '../../../layouts/DashboardLayout/NavBar';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '4rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
    container: {
        width: '100%'
    }
}));

function ContactDetail({match: { params: {id}}}) {
    console.log('Contact ID', id)
    const classes = useStyles();
    let contact = {address:{}, smsHistory:[]};
    //preset job variable for iterable
    //let job = {appliedCandidates: [], assignedRecruiter: '', city: '', clientName: '', createdBy: '', createdOn: '', employmentType: '', industry:'', interviewedCandidates: [], jobDescription: '', jobId: '', jobStatus: '', jobTitle: '', modifiedBy: '', numberOfHired: null, numberOfPositions: null, priority: null, salary :'', searchKeywords: [], skillset:'', state: '', submittedCandidates:[], targetDate: '',workExperience:'',zipCode:'' }
    let [contactDetails, setContactDetails] = useState(contact);
    useEffect(() => {
        //axios.get(`http://localhost:5001/sunshineenterpriseus/us-central1/api/contacts/${id}`, {
        axios.get(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/contacts/${id}`, {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
            }
        })
        .then(res => {
            console.log('success get data')
            console.log('resdata ', res.data);
            let orderedSMSHistory = res.data.smsHistory.reverse();
            setContactDetails({...res.data, address: {...res.data.address}, smsHistory: [...orderedSMSHistory]});
            console.log('contactDetails ', contactDetails)
        })
        .catch(err => {
            console.error(err);
        });
    }, [])
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <ContactDetailComponent contactDetails={contactDetails} />
            </div>
        </div> 
    )
}

export default ContactDetail
