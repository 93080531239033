import React, { useState, useEffect, useContext, useReducer } from 'react';
import {useForm, Controller} from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import InputMask from "react-input-mask";
// import InputMask from "react-input-mask";
import {
    Box,
    Button,
    Container,
    Grid,
    InputAdornment,
    SvgIcon,
    Snackbar,
    Typography,
    makeStyles,
    TextField
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { DataGrid } from '@material-ui/data-grid';
import { Alert, AlertTitle } from '@material-ui/lab';
//import Page from '../components/Page';
//import Profile from './Profile';
import Navbar from '../../../layouts/DashboardLayout/NavBar';
import MuiAlert from '@material-ui/lab/Alert';
//import AddContactForm from './AddContactForm';
import axios from 'axios';
import moment from 'moment';
//import { Alert } from '../_components'
// import { AuthContext } from "../App";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.dark,
//     minHeight: '100%',
//     paddingBottom: theme.spacing(3),
//     paddingTop: theme.spacing(3)
//   }
// }));

// const initialState = {
//     //candidates:[], 
//     isPosting: false,
//     hasError: false
// };
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.black,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
        
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        //flex: '1 1 auto',
        overflow: 'hidden',
        width: '100%',
        paddingTop: '5rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
    table: {
        width: '100%',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    container: {
        width: '100%'
    },
    success: {
        marginTop: "8rem",
        width: '100%',
        '& > * + *': {
        marginTop: theme.spacing(2),
        },
    },
    formButton: {
        marginLeft: 45,
        marginBottom: 45
    }
}));

function SendGroupSMSPage (props) {
    //let startingList = [];
    //console.log('props',props.location.data.contactList)
    const columns = [
        { field: 'name', width: 270, renderHeader: () => (<strong style={{fontSize: 17}}>Name</strong>)},
        { field: 'mobile', width: 200, renderHeader: () => (<strong style={{fontSize: 17}}>Mobile</strong>)},
    ];
    let inputCustomNumber = '';
    let rows = props.location.data.contactList.rows
    let data = {columns:columns, rows:rows};
    console.log('data for datagrid ', data)
    console.log('from Send Group SMS Page', props.location.data.data);
    const {register, control, getValues, setValue , handleSubmit} = useForm();
    const classes = useStyles();
    const [successCode, setSuccessCode] = useState(false);
    const [errorCode, setErrorCode] = useState();
    const [open, setOpen] = useState(false);
    const [contactList, setNumberList] = useState([])
    const [customList, setCustomList] = useState([])
    const [filter, setFilter] = useState('')
    const [filterNumber, setFilterNumber] = useState('')
    const [errorNumberSent, setErrorNumberSent] = useState(0);
    const [searchBy, setSearchBy] = useState('name');

    const handleRowClick = (contact) => {
        //console.log('Row select ', contact)
        console.log('old list ', contactList)
        if (contact.isSelected === true){
            setNumberList([...contactList, contact.data.mobile])
            console.log('new list ', contactList)
        } else {
            setNumberList(contactList.filter(item => item != contact.data.mobile))
        }
    }  
    const onVerifyCustomList = (inputCustomNumber) => {
        console.log('input number ', inputCustomNumber.customNumber.trim().length)
        if (inputCustomNumber.customNumber.trim().length === 12) {
            setCustomList([...customList, inputCustomNumber.customNumber]);
        } else {
            setErrorCode(422)
            setOpen(true);
        }
    }
    const searchContact = (contactName) => {
        console.log('contact name in filter ', contactName)
        setFilter(contactName)
    }
    const searchPhone = (phone) => {
        console.log('contact name in filter ', phone)
        setFilterNumber(phone)
    }
    const handleSearchChange = event => {
        console.log('value ', event.target.value);
        searchContact(event.target.value);
    }
    const handleSearchPhoneChange = event => {
        console.log('value', event.target.value);
        searchPhone(event.target.value);
    }

    const sendSMS = (data) => {
        console.log('from Send Group SMS Page', data);
        if(data.customList === '' && data.contactList === ''){
            setErrorCode(404)
            return setOpen(true);
        }
        //console.log('from rows ', rows)
        data.contactListObjects = rows.filter(row => data.contactList.includes(row.mobile));
        console.log('contact list object ', data.contactListObjects);
        //console.log('token ', 'Bearer ' + JSON.parse(localStorage.tokens))
        //axios.post('http://localhost:5001/sunshineenterpriseus/us-central1/api/jobs', {
        //let expired = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUwOGI0NzM0YjYxNmE0MWFhZmE5MmNlZTVjYzg3Yjc2MmRmNjRmYTIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVnUgTmd1eWVuIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3N1bnNoaW5lZW50ZXJwcmlzZXVzIiwiYXVkIjoic3Vuc2hpbmVlbnRlcnByaXNldXMiLCJhdXRoX3RpbWUiOjE2MDk5NjY4MDcsInVzZXJfaWQiOiI2MHpBMURVTlZIVUI3cmpzaHk1ZXRpZUFNa0YzIiwic3ViIjoiNjB6QTFEVU5WSFVCN3Jqc2h5NWV0aWVBTWtGMyIsImlhdCI6MTYwOTk2NjgwNywiZXhwIjoxNjA5OTcwNDA3LCJlbWFpbCI6Im5ndXllbmFuaGhvYW52dUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibmd1eWVuYW5oaG9hbnZ1QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.xHr1WG3cud7jO0pJlNOiChuEteD1BG47t1TB0BUrw7sZX8EAl-WKJ057JODnr-1wi5Xhetkk3H_Ls4LtJ2W28BrpJ-Puy681P6znSPAvxz3Xp3XgkK1SnuNqwaUL4BVjFpmwLKLNRo7UY14USP_2JfGeePURkez2v2z6C9kzbt4lIJcVwP4J6Kp6fHJAkVXPzECsc5n_mex9_ZxGJB3q6T13HDFCtyKzWmyWMI22sX-BgUXrx7aj_a__z7LueatKB30WXPd5SElMGci48P8aZbbGcoveIbyzmuQ-2Y4FjwfPn77J8UjEI58v_eoWxVM07lBp1kGT9JL1TLHza3wSUw";
        axios.post('https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/contacts/sms/sendgroup/send', data,
        //axios.post('http://localhost:5001/sunshineenterpriseus/us-central1/api/contacts/sms/sendgroup/send', data,
        {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
                //Authorization: 'Bearer ' + ' ' + expired
            }
        },
        )
        .then(res => {
            console.log('status response from twilio ', res.data)
            if (res.data.twilio){
                if(res.data.twilio.errorNumber === 0) {
                    setSuccessCode(true);
                    setOpen(true);
                } else {
                    console.log('status response has error in then ')
                    setSuccessCode(false);
                    setErrorNumberSent(res.data.twilio.errorNumber)
                    setErrorCode(21211);
                    //console.log('SET ERROR CODE IS ', errorCode)
                    setOpen(true);
                }
            } 
        })
        .catch(err => {
            console.log('error res ', err)
            //if (err.response)
            //console.error(err.response);
            //setErrorCode(err.response.status);
            setOpen(true);
        });
        
    }
    const onSubmit = (data) => {
        console.log('data from submit ', data)
        //event.preventDefault()
        sendSMS(data);
    }
    

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid item md={4} xs={12}>
                            <Typography variant="h4">Contact List</Typography>
                            <Box height={700}>
                                

                                <Box minWidth={40}>
                                    <TextField
                                        fullWidth
                                        onChange = {handleSearchPhoneChange}
                                        //value = {props.value}
                                        InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <SvgIcon
                                                fontSize="small"
                                                color="action"
                                            >
                                                <SearchIcon />
                                            </SvgIcon>
                                            </InputAdornment>
                                        )
                                        }}
                                        // endAdornment={
                                        //     <InputAdornment position="end">Hey
                                        //       {/* <IconButton
                                        //         aria-label="toggle password visibility"
                                        //         // onClick={handleClickShowPassword}
                                        //         // onMouseDown={handleMouseDownPassword}
                                        //         edge="end"
                                        //       > */}
                                        //         {/* {values.showPassword ? 1 : 2} */}
                                        //       {/* </IconButton> */}
                                        //     </InputAdornment>
                                        // }
                                        placeholder="Search contact"
                                        variant="outlined"
                                    />
                                </Box>
                                      





                                <DataGrid  checkboxSelection  {...data} pageSize={50} onRowSelected={handleRowClick}
                                    filterModel={{ items: [{ columnField: 'mobile', operatorValue: 'contains', value: filterNumber}, { columnField: 'name', operatorValue: 'contains', value:filter} ]}} 
                                />
                            </Box>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Box mb={3} mt={3} ml={3} display="flex" alignItems="flex-end">
                                <form onSubmit={handleSubmit(onVerifyCustomList)}>
                                    <InputMask
                                        mask="+19999999999"
                                        //value={inputCustomNumber}
                                        disabled={false}
                                        maskChar=" "
                                        //inputRef={register}
                                        name="customNumber"
                                        //onChange={handleCustomNumberChange}
                                        >
                                        {() => <TextField 
                                            label="add custom number"
                                            name="customNumber"
                                            inputRef={register}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton type="submit" color="primary">
                                                        <SvgIcon
                                                            fontSize="small"
                                                            color="action"
                                                        >
                                                            <AddIcon />
                                                        </SvgIcon>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />}
                                    </InputMask>
                                {/* <TextField 
                                    InputLabelProps={{ shrink: true }}
                                    label="add custom number"
                                    name="customNumber"
                                    //onChange={handleCustomNumberChange}
                                    inputRef={register}
                                    //value={inputCustomNumber}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton type="submit" color="primary">
                                                <SvgIcon
                                                    fontSize="small"
                                                    color="action"
                                                >
                                                    <AddIcon />
                                                </SvgIcon>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                /> */}
                                </form>
                            </Box>
                            <Box ml={3}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={3}>
                                        <Grid item md={10} xs={12}>
                                            <TextField
                                                //className={classes.textFieldWidth}
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label="Contact List"
                                                name="contactList"
                                                //required
                                                //variant="outlined"
                                                inputRef={register}
                                                value={contactList}
                                            />
                                        </Grid>
                                        <Grid item md={10} xs={12}>
                                            <TextField
                                                //className={classes.textFieldWidth}
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label="Custom List"
                                                name="customList"
                                                //required
                                                //variant="outlined"
                                                inputRef={register}
                                                value={customList}
                                            />
                                        </Grid >
                                        <Grid item md={10} xs={12}>
                                            <TextField
                                                //className={classes.textFieldWidth}
                                                //InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label="SMS Body"
                                                name="body"
                                                required
                                                multiline={true}
                                                rows={4}
                                                variant="outlined"
                                                inputRef={register}
                                                //value={jobDetails.jobId}
                                            />
                                        </Grid>
                                        <Box mt={3}>
                                            <Grid container spacing={3} className={classes.formButton}>
                                                <Grid item>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        Send
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        color="default"
                                                        variant="contained"
                                                        //type="submit"
                                                        onClick = {() => window.history.back()}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        
                                    </Grid>                                                                                              
                                </form>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    {(() => {
                        if (successCode === true) {
                            return <Alert onClose={handleClose} severity="success">SMS Message sent</Alert>
                        } else if (errorCode === 21211) {
                            return <Alert onClose={handleClose} severity="error">{errorNumberSent} mobile number either invalid or was not sent</Alert>
                        } else if (errorCode === 404) {
                            return <Alert onClose={handleClose} severity="error">No phone number to send</Alert>
                        } else if (errorCode === 403) {
                            return <Alert onClose={handleClose} severity="error">Authentication expired! Please click <Link to='/' >here</Link> to go back to Login</Alert>
                        } else if (errorCode === 422) {
                            return <Alert onClose={handleClose} severity="error">Phone number too short!</Alert>
                        } else {
                            return <Alert onClose={handleClose} severity="error">SMS message sent fail!</Alert>
                        }
                    })()}
                </Snackbar>
            </div>
        </div>
    );
};

export default SendGroupSMSPage;
