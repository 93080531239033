import react from 'react';
//import PropTypes from 'prop-types';
//import clsx from 'clsx';
import {Link} from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const JobToolbar = ({ className, data, searchJob, ...rest }) => {
  const classes = useStyles();
  //console.log('from job toolbar', data.rows)
  const handleChange = event => {
    console.log('value ', event.target.value);
    searchJob(event.target.value);
  }
  return (
    <div
    //   className={clsx(classes.root, className)}
    //   {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        
        <Button
          color="primary"
          variant="contained"
          button key='addJob' component={Link} to='/app/add/jobs'
        >
          Add Job
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                onChange = {handleChange}
                //value = {props.value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search job"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

// JobsToolbar.propTypes = {
//   className: PropTypes.string
// };

export default JobToolbar;
