import React, { useContext, useState } from 'react';
import {useForm} from 'react-hook-form';
import { Link, Redirect, useHistory, withRouter } from "react-router-dom";
//import {  withRouter } from "react-router";

import AppIcon from '../../../icons/fullIcon.png'
import {
    Grid,
    Typography,
    TextField,
    Button,
    Box
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
//import { useAuth } from '../../contexts/AuthContext';

const BlueTextTypography = withStyles({
    root: {
      color: "#2c061f"
    }
  })(Typography);

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.white,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 160,
    },
    image: {
        width: '350px'
    },
    button: {
        marginTop: '2rem'
    },
    box : {
        marginTop: '1rem'
    }
}));

const ResetPassword = (props) => {
    const [responseMessage, setReponseMessage] = useState("");
    //const [isError, setIsError] = useState(false);
    console.log('PRINT HERE')
    const classes = useStyles();
    const {register, handleSubmit} = useForm();
    const onSubmit = (data) => {
        //axios.post('http://localhost:5001/sunshineenterpriseus/us-central1/api/account/password', {email: data.email})
        axios.post('https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/account/password', {email: data.email})
        .then(res => {
            console.log('post success');
            console.log(res.data)
            console.log('type ', typeof(res.data.error));
            if (res.data.error === 'Error: There is no user record corresponding to this identifier. The user may have been deleted.') {
                setReponseMessage('Wrong email address');
                console.log('response message ', responseMessage);
            }
            if (res.data.error === 'Error: The email address is badly formatted.') {
                setReponseMessage('Wrong email address format');
            }
            if (res.data.message === 'email for reset password sent') {
                console.log('success');
                setReponseMessage(res.data.message);
                console.log('response message ', responseMessage);
            }
 
        })
        .catch(err => {
            //console.error(err);
            console.log('code ', err.response);
            // if ()
            // setIsError(true);
            // setErrorCode(err.response.data.error.code);
            //updateUserAuth ({ loginError: true });
            //history.push('/login');
        })
    }
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
            <Grid container >
                <Grid item sm/>
                <Grid item sm>
                    <img src={AppIcon} alt='sunshine enterprise icon' className={classes.image} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField 
                            id='email' 
                            name='email' 
                            type='email' 
                            label='Email' 
                            required
                            inputRef={register}
                            fullWidth 
                        />
                        <Box className={classes.box}/>
                        {(responseMessage && responseMessage==='email for reset password sent') 
                            ? <BlueTextTypography>Reset password link was sent to your email. Click <Link to='/'>here</Link> to go back to Login Page</BlueTextTypography> 
                            : <Typography color='error'>{responseMessage}</Typography>
                        }
                        <Button  className={classes.button} type='submit' variant='contained' color='primary'> Reset Password </Button>
                        
                    </form>
                </Grid>
                <Grid item sm/>
            </Grid>
            </div>
        </div>
        
    );
}

export default ResetPassword;