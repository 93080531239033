import React, { useState, useEffect, useContext } from 'react';
import { 
    makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import CandidateDetailComponent from './CandidateDetailPage';
import Navbar from '../../../layouts/DashboardLayout/NavBar';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '4rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
    container: {
        width: '100%'
    }
}));

function CandidateDetail( {match: { params: {id}}} ) {
    console.log('CANDIDATE ID', id)
    const classes = useStyles();
    // preset candidate variable for iterable
    let candidate = {appliedJobs: [], accountId: '', email: '', firstName: '', interviewedBy: [], notes: [], isCandidate: true, isHired: false, isOnCall: false, lastName: '', phoneNumber: '', position: '', rating: 0, resume: '', type: '', userId: '', zipCode: '' }
    let [candidateDetails, setCandidateDetails] = useState(candidate)
    useEffect(() => {
        axios.get(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/candidates/${id}`, {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
            }
        })
        .then(res => {
            console.log('resdata ', res.data);
            setCandidateDetails({...res.data, appliedJobs: [...res.data.appliedJobs], interviewedBy: [...res.data.interviewedBy], notes: [...res.data.notes]});
        })
        .catch(err => {
            console.error(err);
        });
    }, [])
    
    //console.log
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <CandidateDetailComponent candidateDetails={candidateDetails} />
            </div>
        </div> 
    )
}

export default CandidateDetail
