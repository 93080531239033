import React, { useState, useEffect, useContext } from 'react';
import {useForm, Controller} from 'react-hook-form';
import { Link, useHistory } from "react-router-dom";
import PhoneIcon from '@material-ui/icons/Phone';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Container,
    withStyles,
    makeStyles,
    CardMedia,
    Snackbar,
} from '@material-ui/core';
import moment from 'moment';
//import ContactDetailComponent from './ContactDetail';
import Navbar from '../../../layouts/DashboardLayout/NavBar';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '4rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
    container: {
        width: '100%'
    },
    avatar: {
        display: 'flex',
        '& > *': {
         margin: theme.spacing(1),
        },
    },
    titleBox: {
        display: 'flex',
        marginBottom: '1rem',
        //marginTop: '2rem',
        backgroundColor: 'none'
    },
    left: {
        marginTop: '2rem',
        marginLeft: '1rem'
    },
    right:{
        marginTop: '2rem',
        marginLeft: '2rem',
        display: 'block'
    },
    media: {
        margin: 'auto',
        width: 70,
        height: 70
    },
    workIcon: {
        width: 60,
    },
    cardContent: {
        paddingTop: '2rem',
    },
    table: {
        backgroundColor: '#e6e6e6',
        marginBottom: '1rem',
        width: '94%',
        marginLeft: '55px'
    },
    tableRow: {
        border: 'none'
    },
    tableCellHead: {
        width: '25%',
        paddingLeft: '2rem'
    },
    summaryTableCell: {
        backgroundColor: 'white',
        width: '25%',
        paddingLeft: '3rem'
    },
    tableCellData: {
        backgroundColor: 'white',
        width: '25%',
    },
    // purple: {
    //     color: theme.palette.getContrastText(deepPurple[500]),
    //     backgroundColor: deepPurple[500],
    // },
    textFieldWidth: {
        width: '80%'
    },
    typography: {
        paddingLeft: 65
    },
    editor: {
        //width: '94%'
        marginLeft: 50,
        marginRight: 30
    },
    contentStyles:{},
    tableHead: {
        fontWeight: 'bold',
    },
    formButton: {
        marginLeft: 45,
        marginBottom: 45
    }
}));

function EditContact(props) {
    let contactDetails = props.location.state.details;
    const {register, errors, control, getValues, setValue , handleSubmit} = useForm();
    const [successCode, setSuccessCode] = useState(false);
    const [errorCode, setErrorCode] = useState();
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    //let contact = {address:{}};
    //preset job variable for iterable
    //let job = {appliedCandidates: [], assignedRecruiter: '', city: '', clientName: '', createdBy: '', createdOn: '', employmentType: '', industry:'', interviewedCandidates: [], jobDescription: '', jobId: '', jobStatus: '', jobTitle: '', modifiedBy: '', numberOfHired: null, numberOfPositions: null, priority: null, salary :'', searchKeywords: [], skillset:'', state: '', submittedCandidates:[], targetDate: '',workExperience:'',zipCode:'' }
    //let [contactDetails, setContactDetails] = useState(contact);
    // useEffect(() => {
    //     axios.get(`http://localhost:5001/sunshineenterpriseus/us-central1/api/contacts/${id}`, {
    //     //axios.get(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/contacts/${id}`, {
    //         headers: {
    //             Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
    //         }
    //     })
    //     .then(res => {
    //         console.log('success get data')
    //         console.log('resdata ', res.data);
    //         setContactDetails({...res.data, address: {...res.data.address}});
    //         console.log('contactDetails ', contactDetails)
    //     })
    //     .catch(err => {
    //         console.error(err);
    //     });
    // }, [])
    const isEmail = (email) => {
        const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.match(regEx)) return true;
        else return false;
    }
    const onSubmit = (data) => {
        console.log('data from submit ', data);
        console.log('validate email ', isEmail(data.personalEmail));
        console.log('error code for email ', errorCode)
        if (data.personalEmail!=='' ){
            //isEmail(data.personalEmail)
            if (!isEmail(data.personalEmail)){
                setErrorCode('invalid personalEmail')
                console.log('error code for personal email ', errorCode)
                return;
            } else {
                //setErrorCode('valid email')
            }
        }
        if (data.workEmail!=='' ){
            //isEmail(data.personalEmail)
            if (!isEmail(data.workEmail)){
                setErrorCode('invalid workEmail')
                console.log('error code for work email ', errorCode)
                return;
            } else {
                //setErrorCode('valid email')
            }
        }
        //axios.put(`http://localhost:5001/sunshineenterpriseus/us-central1/api/contacts/update/${data.contactId}`, data, 
        axios.put(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/contacts/update/${data.contactId}`, data, 
        {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
                //Authorization: 'Bearer ' + ' ' + expired
            }
        })
        .then(axiosRes => {
            setSuccessCode(true);
            setOpen(true);
            console.log('success res ', axiosRes.data)
        })
        .catch(err => {
            console.log('error res ', err.response)
            setErrorCode(err.response.status);
            setOpen(true);
        });
        //event.preventDefault()
        //updateJob(data);
    }

    function deleteContact(){
        //axios.delete(`http://localhost:5001/sunshineenterpriseus/us-central1/api/contacts/${contactDetails.contactId}`, 
        axios.delete(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/contacts/${contactDetails.contactId}`,  
        {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
                //Authorization: 'Bearer ' + ' ' + expired
            }
        })
        .then(axiosRes => {
            setSuccessCode('contact deleted');
            setOpen(true);
            console.log('success res ', axiosRes.data)
        })
        .catch(err => {
            console.log('error res ', err.response)
            setErrorCode(err.response.status);
            setOpen(true);
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Container maxWidth={false}>
                        <Box display="flex">
                            <Box display="flex" width="100%" className={classes.titleBox}>
                                <div className={classes.left}>
                                    <CardMedia className={classes.media}><Avatar className={classes.purple, classes.media}><PhoneIcon fontSize="large"/></Avatar></CardMedia>
                                </div>
                                <div className={classes.right}>
                                    {/* <Typography>{name}</Typography> */}
                                    <Typography variant="h3" fontWeight="bold" align="left">{contactDetails.name}</Typography>
                                    {/* <Typography align="left">{contactDetails.contactStatus}</Typography> */}
                                </div>
                            </Box>
                            <Box flexShrink={1} p={3} mr={3}>
                                <Link to={{pathname:`/app/contacts/${contactDetails.contactId}/edit`, state: {details: contactDetails}}}>
                                    {/* <EditIcon color="primary" fontSize="large" ></EditIcon> */}
                                </Link>
                            </Box>
                        </Box>
                        <Card>
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={1} alignItems="center" justify="flex-start">   
                                    <Grid item md={12} xs={12} order={1}>
                                        <Typography component="div" align="left" className={classes.typography}>
                                            <Box fontWeight="fontWeightBold">
                                                Contact Info
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Contact Name"
                                            placeholder="Contact Name"
                                            name="name"
                                            required
                                            //variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            inputRef={register}
                                            defaultValue={contactDetails.name}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="Contact ID"
                                            name="contactId"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            value={contactDetails.contactId}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="Mobile"
                                            name="mobile"
                                            required
                                            inputRef={register}
                                            defaultValue={contactDetails.mobile}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            //floatingLabelFixed={true}
                                            label={"Home Phone"}
                                            placeholder="Home Phone"
                                            name="homePhone"
                                            //required
                                            inputRef={register}
                                            defaultValue={contactDetails.homePhone}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            //floatingLabelFixed={true}
                                            label={"Work Phone"}
                                            placeholder="Work Phone"
                                            name="workPhone"
                                            //required
                                            inputRef={register}
                                            defaultValue={contactDetails.workPhone}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            //floatingLabelFixed={true}
                                            label={"Company"}
                                            placeholder="Company"
                                            name="company"
                                            //required
                                            inputRef={register}
                                            defaultValue={contactDetails.company}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            //floatingLabelFixed={true}
                                            label={"Title"}
                                            placeholder="Title"
                                            name="title"
                                            //required
                                            inputRef={register}
                                            defaultValue={contactDetails.title}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            error ={errorCode ==='invalid personalEmail' ? true : false}//{errorCode==='invalid email' ? true : false }
                                            helperText= {errorCode==='invalid personalEmail' && 'invalid email format'}
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            //floatingLabelFixed={true}
                                            label={"Personal Email"}
                                            placeholder="Personal Email"
                                            name="personalEmail"
                                            //required
                                            inputRef={register}
                                            defaultValue={contactDetails.personalEmail}
                                        />
                                    </Grid>
                                    
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            error ={errorCode ==='invalid workEmail' ? true : false}
                                            helperText= {errorCode==='invalid workEmail' && 'invalid email format'}
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            //floatingLabelFixed={true}
                                            label={"Work Email"}
                                            placeholder="Work Email"
                                            name="workEmail"
                                            //required
                                            inputRef={register}
                                            defaultValue={contactDetails.workEmail}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12} >
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold" paddingTop={2}>
                                                Address Info
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Street"
                                            name="street"
                                            InputLabelProps={{ shrink: true }}
                                            //required
                                            inputRef={register}
                                            defaultValue={contactDetails.address.street}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="City"
                                            name="city"
                                            InputLabelProps={{ shrink: true }}
                                            //required
                                            inputRef={register}
                                            defaultValue={contactDetails.address.city}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="State"
                                            name="state"
                                            InputLabelProps={{ shrink: true }}
                                            //required
                                            inputRef={register}
                                            defaultValue={contactDetails.address.state}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Zip Code"
                                            name="zipCode"
                                            InputLabelProps={{ shrink: true }}
                                            //required
                                            inputRef={register}
                                            defaultValue={contactDetails.address.zipCode}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12} >
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold" paddingTop={2}>
                                                SMS History
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={12} xs={12} />
                                    <Grid item md={12} xs={12} />
                                    <Grid container spacing={3} className={classes.formButton}>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                //type="submit"
                                                onClick = {deleteContact}
                                            >
                                                Delete
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="default"
                                                variant="contained"
                                                //type="submit"
                                                onClick = {() => window.history.back()}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid >
                                </Grid>
                            </CardContent>
                        </Card>
                        <Snackbar open={open} autoHideDuration={15000} onClose={handleClose}>
                            {(() => {
                                if (successCode === true) {
                                    return <Alert onClose={handleClose} severity="success">Contact updated.</Alert>
                                } else if (successCode === 'contact deleted') {
                                    return <Alert onClose={handleClose} severity="success">Contact deleted. </Alert>
                                } else if (errorCode === 403) {
                                    return <Alert onClose={handleClose} severity="error">Authentication expired! Please click <Link to='/' >here</Link> to go back to Login</Alert>
                                } else {
                                    return <Alert onClose={handleClose} severity="error">Update contact fail!</Alert>
                                }
                            })()}
                        </Snackbar>
                    </Container>
                </form>
            </div>
        </div> 
    )
}

export default EditContact