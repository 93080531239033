import React, { useState, useEffect, useContext, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  Snackbar,
  makeStyles
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
//import Page from '../components/Page';
//import Profile from './Profile';
import Navbar from '../../layouts/DashboardLayout/NavBar';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import moment from 'moment';
import ContactForm from './SendgridContact';
//import { Alert } from '../_components'
// import { AuthContext } from "../App";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.black,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
        
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        //flexDirection: 'row',
        //flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '5rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
}));

function SendGridContacts (props) {
    console.log('props from sendgrid page ', props)
    let data=props.location.state.data
    // let sendgridContacts = props.location.state.data.returnList;
    // let jobId = props.location.state.data.returnList
    //let key='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';
    //let url = 'https://api.sendgrid.com/v3/marketing/contacts'
    // axios.get(url, {headers: {'Authorization': `Bearer ` + key }})
    // .then(res => {
    //     console.log(res.data.result)
    // })

    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <ContactForm data={data}/>
                {/* <Container flex>
                    <Grid container spacing={4}>
                        SENDGRID
                    </Grid>
                </Container> */}
            </div>
        </div>
    )
}

export default SendGridContacts;