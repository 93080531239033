import React, { useState, useEffect, useContext, useReducer } from 'react';
import WorkIcon from '@material-ui/icons/Work';
import {useForm, Controller} from 'react-hook-form';
import { Link, useHistory } from "react-router-dom";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MuiAlert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Container,
    withStyles,
    makeStyles,
    CardMedia,
    Snackbar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Navbar from '../../../layouts/DashboardLayout/NavBar';
//import AddJobForm from './AddJobForm';
import axios from 'axios';
import moment from 'moment';

const StyledTableCell  = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 24,
      backgroundColor: theme.palette.primary.dark,
    },
}))(TableCell);

const StyledButton = withStyles({
    root: {
      //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      borderRadius: 3,
      border: 0,
      //color: 'white',
      height: 48,
      padding: '0 30px',
      //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
      textTransform: 'capitalize',
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.black,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
        
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        //flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '5rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
    avatar: {
        display: 'flex',
        '& > *': {
         margin: theme.spacing(1),
        },
    },
    titleBox: {
        display: 'flex',
        marginBottom: '1rem',
        //marginTop: '2rem',
        backgroundColor: 'none'
    },
    left: {
        marginTop: '2rem',
        marginLeft: '1rem'
    },
    right:{
        marginTop: '2rem',
        marginLeft: '2rem',
        display: 'block'
    },
    media: {
        margin: 'auto',
        width: 70,
        height: 70
    },
    workIcon: {
        width: 60,
    },
    cardContent: {
        paddingTop: '2rem',
    },
    table: {
        backgroundColor: '#e6e6e6',
        marginBottom: '1rem',
        width: '94%',
        marginLeft: '55px'
    },
    tableRow: {
        border: 'none'
    },
    tableCellHead: {
        width: '25%',
        paddingLeft: '2rem'
    },
    summaryTableCell: {
        backgroundColor: 'white',
        width: '25%',
        paddingLeft: '3rem'
    },
    tableCellData: {
        backgroundColor: 'white',
        width: '25%',
    },
    purple: {
        //color: theme.palette.getContrastText(deepPurple[500]),
        //backgroundColor: deepPurple[500],
    },
    textFieldWidth: {
        width: '80%'
    },
    typography: {
        paddingLeft: 65
    },
    editor: {
        //width: '94%'
        marginLeft: 50,
        marginRight: 30
    },
    contentStyles:{},
    tableHead: {
        fontWeight: 'bold',
    },
    success: {
        marginTop: "8rem",
        width: '100%',
        '& > * + *': {
        marginTop: theme.spacing(2),
        },
    },
    formButton: {
        marginLeft: 45,
        marginBottom: 45
    }
}));

const priority = [
    {
        value: 1,
        label: '1'
    },
    {
        value: 2,
        label: '2'
    },
    {
        value: 3,
        label: '3'
    },
    {
        value: 4,
        label: '4'
    }
];

const jobStatus = [
    {
        value: 'In-progress',
        label: 'In Progress'
    },
    {
        value: 'Filled',
        label: 'Filled'
    },
    {
        value: 'Declined',
        label: 'Declined'
    },
    {
        value: 'On-hold',
        label: 'On Hold'
    },
]

const employmentType = [
    {
        value: 'Full Time',
        label: 'Full Time'
    },
    {
        value: 'Part Time',
        label: 'Part Time'
    },
    {
        value: 'contract',
        label: 'Contract'
    },
    {
        value: 'temp-to-perm',
        label: 'Temp-to-perm'
    },
    {
        value: 'On Call',
        label: 'On Call'
    },
]

function required(displayName) {
    return function validateRequired(value) {
      // console.log("VALIDATING: ", displayName, value);
      return value !== null || `${displayName} is required.`;
    };
}

function EditJobPage(props) {
    const {register, control, getValues, setValue , handleSubmit} = useForm();
    const [selectedDate, setDate] = useState(new Date());
    const [successCode, setSuccessCode] = useState(false);
    const [errorCode, setErrorCode] = useState();
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const [text, setText] = useState('');
    //
    const classes = useStyles();
    let jobDetails = props.history.location.state.details;
    // const onSubmit = (data) => {
    //     //newJob(data);
    //     console.log('from editJob ', data)
    // }
    const handleDateChange = date => {
        console.log("targetDate CHANGED: ", date);
        setDate(date)
        setValue("targetDate", date);
    };

    const values = getValues();

    function useOnMount(handler) {
        return React.useEffect(handler, []);
    }

    useOnMount(handler => {
        console.log("useEffect register");
        register(
            { name: "targetDate", type: "text" },
            { name: "text", type: "text"},
            { validate: required("Target date") }
        );
    });

    function formatPhoneNumber(number) {
        console.log('TYPE ', typeof(number))
        let newNumber;
        if (number.length > 10) {
            newNumber = '(' + number.split('-')[0] + ') ' + number.split('-')[1] + '-' + number.split('-')[2];
        } else {
            newNumber =  '(' + number.slice(0,3) + ') ' + number.slice(3,6) + '-' + number.slice(6,10);
        }
        return newNumber
    }
    
    function updateJob(data) {
        //console.log('DATA SENT TO UPDATE ', data)
        //let expired = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUwOGI0NzM0YjYxNmE0MWFhZmE5MmNlZTVjYzg3Yjc2MmRmNjRmYTIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVnUgTmd1eWVuIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3N1bnNoaW5lZW50ZXJwcmlzZXVzIiwiYXVkIjoic3Vuc2hpbmVlbnRlcnByaXNldXMiLCJhdXRoX3RpbWUiOjE2MDk5NjY4MDcsInVzZXJfaWQiOiI2MHpBMURVTlZIVUI3cmpzaHk1ZXRpZUFNa0YzIiwic3ViIjoiNjB6QTFEVU5WSFVCN3Jqc2h5NWV0aWVBTWtGMyIsImlhdCI6MTYwOTk2NjgwNywiZXhwIjoxNjA5OTcwNDA3LCJlbWFpbCI6Im5ndXllbmFuaGhvYW52dUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibmd1eWVuYW5oaG9hbnZ1QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.xHr1WG3cud7jO0pJlNOiChuEteD1BG47t1TB0BUrw7sZX8EAl-WKJ057JODnr-1wi5Xhetkk3H_Ls4LtJ2W28BrpJ-Puy681P6znSPAvxz3Xp3XgkK1SnuNqwaUL4BVjFpmwLKLNRo7UY14USP_2JfGeePURkez2v2z6C9kzbt4lIJcVwP4J6Kp6fHJAkVXPzECsc5n_mex9_ZxGJB3q6T13HDFCtyKzWmyWMI22sX-BgUXrx7aj_a__z7LueatKB30WXPd5SElMGci48P8aZbbGcoveIbyzmuQ-2Y4FjwfPn77J8UjEI58v_eoWxVM07lBp1kGT9JL1TLHza3wSUw";
        //axios.put(`http://localhost:5001/sunshineenterpriseus/us-central1/api/jobs/${jobDetails.jobId}`, data,
        axios.put(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/jobs/${jobDetails.jobId}`, data,
            {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
                    //Authorization: 'Bearer ' + ' ' + expired
                }
            },
            )
            .then(res => {
                console.log('resdata ', res.data);
                console.log('Post success');
                setSuccessCode(true);
                setOpen(true);
                //setCandidateDetails({...candidateDetails, ...data});
                //history.push(`/app/job/${jobDetails.jobId}`)
                // window.location.reload();
                //window.location.href = '/app/jobs'
                // return (
                // <Redirect to={`/app/candidates/${candidateDetails.candidateId}`} /> )
            })
            .catch(err => {
                console.error(err);
                setErrorCode(err.response.status);
                setOpen(true);
                // dispatch({
                //     type: 'FETCH_JOBS_FAILURE',
                // });
            });
    }

    const onSubmit = (data) => {
        //event.preventDefault()
        updateJob(data);
    }
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Container maxWidth={false}>
                        <Box display="flex">
                            <Box display="flex" width="100%" className={classes.titleBox}>
                                <div className={classes.left}>
                                    <CardMedia className={classes.media}><Avatar className={classes.purple, classes.media}><WorkIcon fontSize="large"/></Avatar></CardMedia>
                                </div>
                                <div className={classes.right}>
                                    <Typography variant="h3" fontWeight="bold" align="left">{jobDetails.jobTitle}</Typography>
                                    <Typography align="left">{jobDetails.jobStatus}</Typography>
                                </div>
                            </Box>
                        </Box>
                        <Card>
                            <CardContent className={classes.cardContent}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Box width={1}>
                                    <Table size="small" className={classes.table}>
                                        <TableHead>
                                            <TableRow className={classes.tableRow}>
                                                <StyledTableCell  className={classes.tableCellHead} >
                                                    Applied
                                                </StyledTableCell >
                                                <StyledTableCell  className={classes.tableCellHead} >
                                                    Contacted
                                                </StyledTableCell  >
                                                <StyledTableCell  className={classes.tableCellHead}>
                                                    Hired
                                                </StyledTableCell >
                                                <StyledTableCell  Cell className={classes.tableCellHead}>
                                                    Needed
                                                </StyledTableCell >
                                            </TableRow>  
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell className={classes.summaryTableCell}>
                                                    {jobDetails.appliedCandidates.length}
                                                </TableCell>
                                                <TableCell className={classes.summaryTableCell}>
                                                    {jobDetails.interviewedCandidates.length}
                                                </TableCell>
                                                <TableCell className={classes.summaryTableCell}>
                                                    {jobDetails.numberOfHired}
                                                </TableCell>
                                                <TableCell className={classes.summaryTableCell}>
                                                    {jobDetails.numberOfPositions}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>  
                                    </Table>
                                </Box>
                                <Grid container spacing={2} alignItems="center" justify="flex-start">

                                    <Grid item md={12} xs={12} >
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold">
                                                Job Opening Information
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Job Title"
                                            placeholder="Job Title"
                                            name="jobTitle"
                                            required
                                            //variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            inputRef={register}
                                            defaultValue={jobDetails.jobTitle}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Job ID"
                                            name="jobId"
                                            required
                                            //variant="outlined"
                                            //inputRef={register}
                                            value={jobDetails.jobId}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Job Status"
                                            name="jobStatus"
                                            select
                                            SelectProps={{ native: true }}
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.jobStatus}
                                        >
                                            {jobStatus.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Modified By"
                                            name="modifiedBy"
                                            //required
                                            //variant="outlined"
                                            // inputRef={register}
                                            value={jobDetails.modifiedBy}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            //floatingLabelFixed={true}
                                            label={"Priority"}
                                            placeholder="Priority"
                                            name="priority"
                                            required
                                            select
                                            SelectProps={{ native: true }}
                                            inputRef={register}
                                            defaultValue={jobDetails.priority}
                                        >
                                            {priority.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Salary"
                                            name="salary"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.salary}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Skill set"
                                            name="skillset"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.skillset}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="State"
                                            name="state"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.state}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <KeyboardDatePicker
                                            id="targetDate"
                                            name="targetDate"
                                            label="Target Date"
                                            format="MM/dd/yyyy"
                                            disablePast
                                            margin="normal"
                                            disableToolbar
                                            defaultValue = {jobDetails.targetDate}
                                            value={values.targetDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={12} />
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Work Experience"
                                            name="workExperience"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.workExperience}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Zip Code"
                                            name="zipCode"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.zipCode}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Client Name"
                                            name="clientName"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.clientName}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Assigned Recruiter"
                                            name="assignedRecruiter"
                                            required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.assignedRecruiter}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="City"
                                            name="city"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.city}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Created By"
                                            name="createdBy"
                                            required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.createdBy}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Created On"
                                            name="createdOn"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.createdOn}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Employment Type"
                                            name="employmentType"
                                            required
                                            select
                                            SelectProps={{ native: true }}
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.employmentType}
                                        >
                                            {employmentType.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Industry"
                                            name="industry"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={jobDetails.industry}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold">
                                                Applied Candidates
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Table size="small" className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell >
                                                        Name
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        ID
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Applied On
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Phone Number
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Email
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Resume
                                                    </StyledTableCell >
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {jobDetails.appliedCandidates.map(candidate => 
                                                (
                                                <TableRow
                                                    hover
                                                    key={candidate.candidateId}
                                                    //selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                                                    // button key='job' component={Link} to='/app/job/:jobId'
                                                    //onClick={()=> handleRowClick(candidate.candidateId)}
                                                >
                                                    <TableCell className={classes.tableCellData} > 
                                                        <StyledButton>{candidate.candidateName}</StyledButton>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCellData}>
                                                        {candidate.candidateId}
                                                    </TableCell >
                                                    <TableCell className={classes.tableCellData}>
                                                        {moment(candidate.appliedOn).format('MM-DD-YYYY')}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCellData}>
                                                        {formatPhoneNumber(candidate.phoneNumber)}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCellData}>
                                                        {candidate.email}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCellData}>
                                                        <IconButton color="primary" aria-label="Download" href={candidate.resume}>
                                                            {/* <GetAppIcon /> */}
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                )
                                            )}
                                            </TableBody>

                                        </Table>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold">
                                                Interviewed Candidates
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Table size="small" className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell >
                                                        Name
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Interview On
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Interview By
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Rating
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Note
                                                    </StyledTableCell >
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {jobDetails.interviewedCandidates.map(candidate => 
                                                (
                                                <TableRow key={candidate.interviewedOn}>
                                                    <TableCell>
                                                        {candidate.candidateName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(candidate.interviewedOn).format('MM-DD-YYYY')}
                                                    </TableCell>
                                                    <TableCell>
                                                        {candidate.interviewedBy}
                                                    </TableCell>
                                                    <TableCell>
                                                        {candidate.rating}
                                                    </TableCell>
                                                    <TableCell>
                                                        {candidate.notes}
                                                    </TableCell>
                                                </TableRow>
                                                )
                                            )}
                                            </TableBody>

                                        </Table>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold">
                                                Job Description
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12} className={classes.editor}>
                                        <Controller name="jobDescription" control={control} render ={({ onChange, value, name }) => (
                                            <CKEditor
                                            editor={ ClassicEditor }
                                            data={jobDetails.jobDescription}
                                            config={{height:'300px'}}
                                            onInit={ editor => {
                                                // You can store the "editor" and use when it is needed.
                                                console.log( 'Editor is ready to use!', editor );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                value = editor.getData();
                                                onChange(editor.getData());
                                            } }
                                            />
                                        )}/>
                                    </Grid>
                                    <Grid item md={12} xs={12} />
                                    <Grid item md={12} xs={12} />
                                    <Grid container spacing={3} className={classes.formButton}>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="default"
                                                variant="contained"
                                                //type="submit"
                                                onClick = {() => window.history.back()}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid >
                                </Grid>
                            </MuiPickersUtilsProvider>
                            </CardContent>
                        </Card> 
                    </Container>
                </form>

                <Snackbar open={open} autoHideDuration={15000} onClose={handleClose}>
                    {(() => {
                        if (successCode === true) {
                            return <Alert onClose={handleClose} severity="success">Job updated!</Alert>
                        } else if (errorCode === 403) {
                            return <Alert onClose={handleClose} severity="error">Authentication expired! Please click <Link to='/' >here</Link> to go back to Login</Alert>
                        } else {
                            return <Alert onClose={handleClose} severity="error">Updated job fail!</Alert>
                        }
                    })()}
                </Snackbar>
                {/* {successCode ? <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Job updated!
                    </Alert>
                </Snackbar> : <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        Updated job fail!
                    </Alert>
                </Snackbar>} */}
            </div>
        </div>
    )
}

export default EditJobPage
