import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import EditIcon from '@material-ui/icons/Edit';
import WorkIcon from '@material-ui/icons/Work';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from "react-router-dom";
import {useForm, Controller} from 'react-hook-form';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FileIcon from '../../../icons/fileIcon.png';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Container,
    withStyles,
    makeStyles,
    CardMedia
} from '@material-ui/core';

const StyledTableCell  = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 24,
      backgroundColor: theme.palette.primary.dark,
    },
}))(TableCell);

const StyledButton = withStyles({
    root: {
      //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      borderRadius: 3,
      border: 0,
      //color: 'white',
      height: 48,
      padding: '0 30px',
      //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
      textTransform: 'capitalize',
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    avatar: {
        display: 'flex',
        '& > *': {
         margin: theme.spacing(1),
        },
    },
    titleBox: {
        display: 'flex',
        marginBottom: '1rem',
        //marginTop: '2rem',
        backgroundColor: 'none'
    },
    left: {
        marginTop: '2rem',
        marginLeft: '1rem'
    },
    right:{
        marginTop: '2rem',
        marginLeft: '2rem',
        display: 'block'
    },
    media: {
        margin: 'auto',
        width: 70,
        height: 70
    },
    workIcon: {
        width: 60,
    },
    cardContent: {
        paddingTop: '2rem',
    },
    table: {
        backgroundColor: '#e6e6e6',
        marginBottom: '1rem',
        width: '94%',
        marginLeft: '55px'
    },
    tableRow: {
        border: 'none'
    },
    tableCellHead: {
        width: '25%',
        paddingLeft: '2rem'
    },
    summaryTableCell: {
        backgroundColor: 'white',
        width: '25%',
        paddingLeft: '3rem'
    },
    tableCellData: {
        backgroundColor: 'white',
        width: '25%',
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    textFieldWidth: {
        width: '80%'
    },
    typography: {
        paddingLeft: 65
    },
    editor: {
        //width: '94%'
        marginLeft: 50,
        marginRight: 30
    },
    contentStyles:{},
    tableHead: {
        fontWeight: 'bold',
    }
}));

const jobStatus = [
    {
        value: 'In-progress',
        label: 'In Progress'
    },
    {
        value: 'Filled',
        label: 'Filled'
    },
    {
        value: 'Declined',
        label: 'Declined'
    },
    {
        value: 'On-hold',
        label: 'On Hold'
    },
]

export default function JobDetailComponent({jobDetails}) {
    console.log('from coponent ', jobDetails)
    const {register, control, getValues, setValue , handleSubmit} = useForm();
    const history = useHistory();
    const classes = useStyles();
    console.log('from jobdetail component', jobDetails.appliedCandidates)
    const handleNameClick = (candidateId) => {
        console.log('candidateId', candidateId)
        history.push(`/app/candidates/${candidateId}`);
    }
    return (
        <Container maxWidth={false}>
            <Box display="flex">
                <Box display="flex" width="100%" className={classes.titleBox}>
                    <div className={classes.left}>
                        <CardMedia className={classes.media}><Avatar className={classes.purple, classes.media}><WorkIcon fontSize="large"/></Avatar></CardMedia>
                    </div>
                    <div className={classes.right}>
                        <Typography variant="h3" fontWeight="bold" align="left">{jobDetails.jobTitle}</Typography>
                        <Typography align="left">{jobDetails.jobStatus}</Typography>
                    </div>
                </Box>
                <Box flexShrink={1} p={3} mr={3}>
                    <Link to={{pathname:`/app/jobs/${jobDetails.jobId}/edit`, state: {details: jobDetails}}}>
                        <EditIcon color="primary" fontSize="large" ></EditIcon>
                    </Link>
                </Box>
            </Box>
            <Card>
                <CardContent className={classes.cardContent}>
                    <Box width={1}>
                        <Table size="small" className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <StyledTableCell  className={classes.tableCellHead} >
                                        Applied
                                    </StyledTableCell >
                                    <StyledTableCell  className={classes.tableCellHead} >
                                        Contacted
                                    </StyledTableCell  >
                                    <StyledTableCell  className={classes.tableCellHead}>
                                        Hired
                                    </StyledTableCell >
                                    <StyledTableCell  Cell className={classes.tableCellHead}>
                                        Needed
                                    </StyledTableCell >
                                </TableRow>  
                            </TableHead>
                            <TableBody>
                                <TableRow >
                                    <TableCell className={classes.summaryTableCell}>
                                        {jobDetails.appliedCandidates.length}
                                    </TableCell>
                                    <TableCell className={classes.summaryTableCell}>
                                        {jobDetails.interviewedCandidates.length}
                                    </TableCell>
                                    <TableCell className={classes.summaryTableCell}>
                                        {jobDetails.numberOfHired}
                                    </TableCell>
                                    <TableCell className={classes.summaryTableCell}>
                                        {jobDetails.numberOfPositions}
                                    </TableCell>
                                </TableRow>
                            </TableBody>  
                        </Table>
                    </Box>
                    <Grid container spacing={2} alignItems="center" justify="flex-start">

                        <Grid item md={12} xs={12} >
                            <Typography component="div" align="left" className={classes.typography} >
                                <Box fontWeight="fontWeightBold">
                                    Job Opening Information
                                </Box>
                            </Typography>
                        </Grid >
                        <Grid item md={6} xs={12} >
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Job Title"
                                placeholder="Job Title"
                                name="jobTitle"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.jobTitle}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Job ID"
                                name="jobId"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.jobId}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Job Status"
                                name="jobStatus"
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.jobStatus}
                            >
                            </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Modified By"
                                name="modifiedBy"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.modifiedBy}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                //floatingLabelFixed={true}
                                label={"Priority"}
                                placeholder="Priority"
                                name="priority"
                                required
                                
                                // inputRef={register}
                                value={jobDetails.priority}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Salary"
                                name="salary"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.salary}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Skill set"
                                name="skillset"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.skillset}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="State"
                                name="state"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.state}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Target Date"
                                name="targetDate"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={moment(jobDetails.targetDate).format('MM-DD-YYYY')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Work Experience"
                                name="workExperience"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.workExperience}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Zip Code"
                                name="zipCode"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.zipCode}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Client Name"
                                name="clientName"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.clientName}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Assigned Recruiter"
                                name="assignedRecruiter"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.assignedRecruiter}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="City"
                                name="city"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.city}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Created By"
                                name="createdBy"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.createdBy}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Created On"
                                name="createdOn"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={moment(jobDetails.createdOn).format('MM-DD-YYYY')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Employment Type"
                                name="employmentType"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.employmentType}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Industry"
                                name="industry"
                                required
                                //variant="outlined"
                                // inputRef={register}
                                value={jobDetails.industry}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography component="div" align="left" className={classes.typography} >
                                <Box fontWeight="fontWeightBold">
                                    Applied Candidates
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Table size="small" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell >
                                            Name
                                        </StyledTableCell >
                                        <StyledTableCell >
                                            ID
                                        </StyledTableCell >
                                        <StyledTableCell >
                                            Applied On
                                        </StyledTableCell >
                                        <StyledTableCell >
                                            Phone Number
                                        </StyledTableCell >
                                        <StyledTableCell >
                                            Email
                                        </StyledTableCell >
                                        <StyledTableCell >
                                            Resume
                                        </StyledTableCell >
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {jobDetails.appliedCandidates.map(candidate => 
                                    (
                                    <TableRow
                                        hover
                                        key={candidate.candidateId}
                                        //selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                                        // button key='job' component={Link} to='/app/job/:jobId'
                                        //onClick={()=> handleRowClick(candidate.candidateId)}
                                    >
                                        <TableCell className={classes.tableCellData} > 
                                            <StyledButton onClick={()=> handleNameClick(candidate.candidateId)}>{candidate.candidateName}</StyledButton>
                                        </TableCell>
                                        <TableCell className={classes.tableCellData}>
                                            {candidate.candidateId}
                                        </TableCell >
                                        <TableCell className={classes.tableCellData}>
                                            {new Date(candidate.appliedOn).toLocaleDateString('en-US')}
                                        </TableCell>
                                        <TableCell className={classes.tableCellData}>
                                            ({candidate.phoneNumber.slice(0,3)}) {candidate.phoneNumber.slice(3,6)}-{candidate.phoneNumber.slice(6,10)}
                                        </TableCell>
                                        <TableCell className={classes.tableCellData}>
                                            {candidate.email}
                                        </TableCell>
                                        <TableCell className={classes.tableCellData}>
                                            <IconButton color="primary" aria-label="Download" href={candidate.resume}>
                                                <GetAppIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    )
                                )}
                                </TableBody>

                            </Table>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography component="div" align="left" className={classes.typography} >
                                <Box fontWeight="fontWeightBold">
                                    Interviewed Candidates
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Table size="small" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell >
                                            Name
                                        </StyledTableCell >
                                        <StyledTableCell >
                                            Interview On
                                        </StyledTableCell >
                                        <StyledTableCell >
                                            Interview By
                                        </StyledTableCell >
                                        <StyledTableCell >
                                            Rating
                                        </StyledTableCell >
                                        <StyledTableCell >
                                            Note
                                        </StyledTableCell >
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {jobDetails.interviewedCandidates.map(candidate => 
                                    (
                                    <TableRow key={candidate.interviewedOn}>
                                        <TableCell>
                                            {candidate.candidateName}
                                        </TableCell>
                                        <TableCell>
                                            {moment(candidate.interviewedOn).format('YYYY-MM-DD')}
                                        </TableCell>
                                        <TableCell>
                                            {candidate.interviewedBy}
                                        </TableCell>
                                        <TableCell>
                                            {candidate.rating}
                                        </TableCell>
                                        <TableCell>
                                            {candidate.notes}
                                        </TableCell>
                                    </TableRow>
                                    )
                                )}
                                </TableBody>

                            </Table>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography component="div" align="left" className={classes.typography} >
                                <Box fontWeight="fontWeightBold">
                                    Job Description
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.editor}>
                            <Controller name="jobDescription" control={control} render ={({ onChange, value, name }) => (
                                <CKEditor
                                editor={ ClassicEditor }
                                data={jobDetails.jobDescription}
                                config={{height:'300px'}}
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    value = editor.getData();
                                    onChange(editor.getData());
                                } }
                                />
                            )}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    )
}
