import React, { useState, useEffect, useContext, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    Snackbar,
    makeStyles
} from '@material-ui/core';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import ContactToolbar from '../ContactToolbar';
import Navbar from '../../../layouts/DashboardLayout/NavBar';
import MuiAlert from '@material-ui/lab/Alert';
//import AddJobForm from './AddJobForm';
import axios from 'axios';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.black,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
        
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        //flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '5rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
    gridWrapper: {
        width: '100%'
    },
    table: {
        width: '100%',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    container: {
        width: '100%'
    },
    success: {
        marginTop: "8rem",
        width: '100%',
        '& > * + *': {
        marginTop: theme.spacing(2),
        },
    }
}));

function ContactList (props) {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [contacts, setContacts] = useState([]);
    const [filter, setFilter] = useState('')
    const handleRowClick = (job) => {
        history.push(`/app/jobs/${job.data.jobId}`);
    }  
    const handleCellClick = (contact) => {
        //console.log('Cell data', contact)
        console.log('contact id', contact.row.contactId)
        history.push(`/app/contacts/${contact.row.contactId}`);
    }  
    const searchContact = (contactName) => {
        setFilter(contactName)
    }
    // const [successCode, setSuccessCode] = useState(false);
    // const [errorCode, setErrorCode] = useState();
    // const [open, setOpen] = useState(false);
    let arr = [];
    const columns = [
        { field: 'name', width: 470, renderHeader: () => (<strong style={{fontSize: 17}}><Button>Name</Button></strong>)},
        { field: 'contactId', width: 200, renderHeader: () => (<strong style={{fontSize: 17}}>Contact ID</strong>)},
        { field: 'city', width: 200, renderHeader: () => (<strong style={{fontSize: 17}}>City</strong>)},
        { field: 'email', width: 280, renderHeader: () => (<strong style={{fontSize: 17}}>Email</strong>)},
        { field: 'mobile', width: 280, renderHeader: () => (<strong style={{fontSize: 17}}>Mobile</strong>)},
        { field: 'company', width: 280, renderHeader: () => (<strong style={{fontSize: 17}}>Company</strong>)},
    ];
    const [data, setData] = useState({columns: columns, rows:[]})
    useEffect(() => {
        //axios.get('http://localhost:5001/sunshineenterpriseus/us-central1/api/contacts', {
        axios.get('https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/contacts', {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
            }
        })
        .then(res => {
            let counter = 0;
            res.data.forEach(item => {
                arr.push({
                    name:item.name, 
                    company:item.company, 
                    workEmail: item.workEmail, 
                    //createdOn:moment(item.createdOn).format('M/DD/YYYY'), 
                    personalEmail:item.personalEmail, 
                    description:item.description, 
                    workEmail:item.workEmail, 
                    mobile:item.mobile, 
                    homePhone:item.homePhone,
                    contactId: item.contactId,
                    title: item.title,
                    workPhone: item.workPhone,
                    address: {...item.address},
                    id:counter++
                })
            })
            setLoading(false);
            setData({columns: columns, rows: [...arr]})
            console.log('data ', data)
        })
        .catch(err => {
            console.error(err);
        });
    }, [contacts.length])
    
    // function Alert(props) {
    //     return <MuiAlert elevation={6} variant="filled" {...props} />;
    // }
    function CustomLoadingOverlay() {
        return (
          <GridOverlay>
            <div style={{ position: 'absolute', top: 10, width: '100%' }}>
              <LinearProgress />
            </div>
          </GridOverlay>
        );
    }
    // const handleClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setOpen(false);
    // };
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <Container maxWidth={false} className={classes.container}>
                    <Grid container spacing={3} className={classes.gridWrapper}>
                        <Grid item md={12} xs={12}>
                            <ContactToolbar className={classes.toolbar} data={data} searchContact={searchContact}/>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Card>
                                <Box height={700}>
                                    <DataGrid  
                                        components={{
                                            loadingOverlay: CustomLoadingOverlay,
                                        }} 
                                        loading ={loading}
                                        checkboxSelection  
                                        {...data} 
                                        pageSize={50} 
                                        onCellClick={handleCellClick} filterModel={{
                                            items: [{ columnField: 'name', operatorValue: 'contains', value: filter }],
                                        }} 
                                    />
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item md={12} xs={12} />
                    </Grid>
                </Container>

                {/* <Snackbar open={open} autoHideDuration={15000} onClose={handleClose}>
                    {(() => {
                        if (successCode === true) {
                            return <Alert onClose={handleClose} severity="success">New job added!</Alert>
                        } else if (errorCode === 403) {
                            return <Alert onClose={handleClose} severity="error">Authentication expired! Please click <Link to='/' >here</Link> to go back to Login</Alert>
                        } else {
                            return <Alert onClose={handleClose} severity="error">Added job fail!</Alert>
                        }
                    })()}
                </Snackbar> */}
            </div>
        </div>
    );
};

export default ContactList;
