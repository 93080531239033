import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { 
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Container,
    withStyles,
    makeStyles,
    CardMedia,
    Snackbar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { AuthContext } from '../../../contexts/AuthContext';
import Navbar from '../../../layouts/DashboardLayout/NavBar';
import jwt_decode from 'jwt-decode';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '5rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: '1rem'
        }
    },
    avatarSize: {
        width: 75,
        height: 75
    },
    cardContent: {
        padding: '1rem'
    },
    textFieldWidth: {
        width: '85%'
    },
    typography: {
        paddingLeft: 55
    },
    buttonAlignment: {
        paddingLeft: 55
    }
    
}));

const roles = [
    {
        value: 'admin',
        label: 'Admin'
    },
    {
        value: 'recruiter',
        label: 'Recruiter'
    },
    {
        value: 'marketing',
        label: 'Marketing'
    },
    {
        value: 'web developer',
        label: 'Web developer'
    },
    {
        value: 'other',
        label: 'Other'
    },
]
const types = [
    {
        value: 'nurse',
        label: 'Nurse'
    },
    {
        value: 'teacher',
        label: 'Teacher'
    },
    {
        value: 'none',
        label: 'None'
    },
]
const positions = [
    {
        value: 'CNA',
        label: 'CNA'
    },
    {
        value: 'RN',
        label: 'RN'
    },
    {
        value: 'LPN',
        label: 'LPN'
    },
    {
        value: 'APRNs',
        label: 'APRNs'
    },
    {
        value: 'Teacher',
        label: 'Teacher'
    },
    {
        value: 'Substitute Teacher',
        label: 'Substitute Teacher'
    },
    {
        value: 'others',
        label: 'Others'
    }
];

export default function EditUser(props) {
    let userDetails = props.location.state.details;
    console.log('userdetails ', userDetails)
    const { register, control, getValues, setValue, handleSubmit } = useForm();
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [checked, setChecked] = useState(userDetails.displayBio);
    const [confirmDeletePopOver, setConfirmDeletePopOver] = useState(false);
    const [successPostMessage, setSuccessPostMessage] = useState(false);
    const [errorPostMessage, setErrorPostMessage] = useState();
    const [open, setOpen] = useState(false);
    //const form = useRef(null)
    console.log('edit user details display ', props.location.state.details)
    // const formData = new FormData();
    // formData.append()

    const history = useHistory();
    const classes = useStyles();
    let token = localStorage.getItem('tokens');
    const { authTokens, setAuthTokens } = useContext(AuthContext);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const onSubmit = (data) => {
        data.displayBio = checked;
        console.log('UPDATE CANDIDATE ', data);
        const file = data['upload-photo'][0]
        var formData = new FormData();
        for (var key in data) {
            formData.append(key, data[key])
            //console.log(`append key ${key} and data ${data[key]}`)
        }
        formData.append('file', file)
        // for (var value of formData.values()) {
        //     console.log('value is ', value);
        //  }
        axios.put(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/employeeupdate`, formData,
        //axios.put(`http://localhost:5001/sunshineenterpriseus/us-central1/api/employeeupdate`, formData,
            {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(localStorage.tokens),
                    'Content-Type': 'multipart/form-data'
                    //Authorization: 'Bearer ' + ' ' + expired
                }
            },
            )
        .then(data => {
            console.log('success');
            setSuccessPostMessage(true);
            setOpen(true)
        })
        .catch(err => {
            console.log(err);
            setSuccessPostMessage(false)
            setOpen(true)
        })
        //console.log('form data', formData.getAll())
        //let updates = {...data, ...selectedFiles};
        //console.log('data send ', updates)
        //updateCandidate(data);
    }
    const handleDeleButton = () => {
        setConfirmDeletePopOver(true);
    }
    const handleConfirmDeletePopOverClose = () => {
        setConfirmDeletePopOver(false);
    }
    const onHandleDelete = () => {
        let userId = userDetails.userId + '-' + userDetails.accountId;
        axios.put(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/employees/delete/${userId}`,
        //axios.delete(`http://localhost:5001/sunshineenterpriseus/us-central1/api/employees/delete/${userId}`,
        {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens),
                'Content-Type': 'multipart/form-data'
            }
        },
        )
        .then(data => {
            console.log('success delete employee')
            setConfirmDeletePopOver(false);
            setSuccessPostMessage(true);
            setOpen(true)
        })
        .catch(err => {
            console.log(err);
            setSuccessPostMessage(false)
            setOpen(true)
        })
    }
    const onHandleDisable = () => {
        let userId = userDetails.userId + '-' + userDetails.accountId + '-' + userDetails.accountStatus;
        axios.put(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/employees/deactive/${userId}`,
        //axios.put(`http://localhost:5001/sunshineenterpriseus/us-central1/api/employees/deactive/${userId}`,
        {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens),
                'Content-Type': 'multipart/form-data'
                //Authorization: 'Bearer ' + ' ' + expired
            }
        },
        )
        .then(data => {
            console.log('success deactive employee')
            setSuccessPostMessage(true);
            setOpen(true)
            //setConfirmDeletePopOver(false)
        })
        .catch(err => {
            console.log(err);
            setSuccessPostMessage(false)
            setOpen(true)
        })
    }
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    

    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Container maxWidth={false}>
                        <Box display="flex">
                            <Box display="flex" width="100%" className={classes.titleBox}>
                                <div className={classes.left}>
                                    <CardMedia className={classes.media}>
                                        <Avatar className={classes.purple, classes.media, classes.avatarSize}>
                                            <Typography variant="h5" >{userDetails.firstName.slice(0,1)}{userDetails.lastName.slice(0,1)}</Typography>
                                        </Avatar>
                                    </CardMedia>
                                </div>
                                <div className={classes.right}>
                                    <Box mt={1} ml={3} alignItems="left" className={classes.nameContainer}>
                                        <Typography display="block" variant="h3" fontWeight="bold">{userDetails.firstName} {userDetails.lastName}</Typography>
                                        <Typography display="block" fontWeight="bold">{userDetails.role}</Typography>
                                    </Box>
                                </div>
                            </Box>
                        </Box>
                        <Card>
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={1} alignItems="center" justify="flex-start">   
                                    <Grid item md={12} xs={12} order={1}>
                                        <Typography component="div" align="left" className={classes.typography}>
                                            <Box fontWeight="fontWeightBold">
                                                Employee Info
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="Employee Name"
                                            placeholder="Employee Name"
                                            name="employeeName"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.firstName + ' ' + userDetails.lastName}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="Employee ID"
                                            name="userId"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.userId}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="Account ID"
                                            name="accountId"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.accountId}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="DOB"
                                            name="dob"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.dob}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="Email"
                                            name="email"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.email}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            //fullWidth
                                            //error = {errorMessage.includes('Role must not be empty') ? true : false}
                                            //helperText= {errorMessage.includes('Role must not be empty') && 'Employee must have a role'}
                                            label="Role"
                                            name="role"
                                            required
                                            select
                                            SelectProps={{ native: true }}
                                            variant="outlined"
                                            defaultValue={userDetails.role}
                                            inputRef={register}
                                            //onChange={e => setRole(e.target.value)}
                                        >
                                            {roles.map((role) => (
                                                <option
                                                    key={role.value}
                                                    value={role.value}
                                                >
                                                    {role.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            //fullWidth
                                            //error = {errorMessage.includes('Position must not be empty') ? true : false}
                                            //helperText= {errorMessage.includes('Position must not be empty') && 'Employee must have a position'}
                                            label="Position"
                                            name="position"
                                            required
                                            select
                                            SelectProps={{ native: true }}
                                            variant="outlined"
                                            defaultValue={userDetails.position}
                                            inputRef={register}
                                            //onChange={e => setPosition(e.target.value)}
                                        >
                                            {positions.map((position) => (
                                                <option
                                                    key={position.value}
                                                    value={position.value}
                                                >
                                                    {position.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            //error = {errorMessage.includes('Type must not be empty') ? true : false}
                                            //helperText= {errorMessage.includes('Type must not be empty') && 'Employee must have a type'}
                                            label="Type"
                                            name="type"
                                            //required
                                            select
                                            SelectProps={{ native: true }}
                                            variant="outlined"
                                            defaultValue={userDetails.type}
                                            inputRef={register}
                                            //onChange={e => setType(e.target.value)}
                                        >
                                            {types.map((type) => (
                                                <option
                                                    key={type.value}
                                                    value={type.value}
                                                >
                                                    {type.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="Created On"
                                            name="createdOn"
                                            readOnly
                                            //variant="outlined"
                                            //inputRef={register}
                                            defaultValue={userDetails.createdOn}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12} order={1}>
                                        <Typography component="div" align="left" className={classes.typography}>
                                            <Box fontWeight="fontWeightBold">
                                                Address Info
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="Street"
                                            placeholder="Street"
                                            name="street"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.address.street}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="City"
                                            placeholder="City"
                                            name="city"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.address.city}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="State"
                                            placeholder="State"
                                            name="state"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.address.state}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="Zip code"
                                            placeholder="Zip code"
                                            name="zipcode"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.address.zipcode}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12} order={1}>
                                        <Typography component="div" align="left" className={classes.typography}>
                                            <Box fontWeight="fontWeightBold">
                                                Interviewed Candidates
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={12} xs={12} order={1}>
                                        <Typography component="div" align="left" className={classes.typography}>
                                            <Box fontWeight="fontWeightBold">
                                                Hired Candidates
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={12} xs={12} order={1}>
                                        <Typography component="div" align="left" className={classes.typography}>
                                            <Box fontWeight="fontWeightBold">
                                                Job Posted
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={12} xs={12} order={1}>
                                        <Typography component="div" align="left" className={classes.typography}>
                                            <Box fontWeight="fontWeightBold">
                                                Blog Posted
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={12} xs={12} order={1}>
                                        <Typography component="div" align="left" className={classes.typography}>
                                            <Box fontWeight="fontWeightBold">
                                                Employee Bios
                                            </Box>
                                            <FormControlLabel
                                                control={<Checkbox checked={checked} onChange={handleChange} name="displayBio" color="primary"/>}
                                                label="Display this employee bio on Sunshine Enterprise website"
                                            />
                                        </Typography>
                                    </Grid >
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="Biography"
                                            placeholder="Biography"
                                            name="biography"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.biography}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            
                                            InputLabelProps={{ shrink: true }}
                                            label="Display position"
                                            placeholder="Display position"
                                            name="displayPosition"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.displayPosition}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            
                                            InputLabelProps={{ shrink: true }}
                                            label="Department"
                                            placeholder="Department"
                                            name="department"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.department}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            
                                            InputLabelProps={{ shrink: true }}
                                            label="Rank"
                                            placeholder="Rank"
                                            name="rank"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={userDetails.rank}
                                        />
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                    </Grid >
                                    <Grid item md={12} xs={12}>
                                    </Grid >

                                    <Grid item md={6} xs={12} >
                                        <label htmlFor="upload-photo">
                                        <input
                                            style={{ display: 'none' }}
                                            id="upload-photo"
                                            name="upload-photo"
                                            type="file"
                                            //onChange={selectFile}
                                            ref={register}
                                        />

                                        <Button 
                                            startIcon={<CloudUploadIcon />}
                                            color="default" 
                                            variant="contained" 
                                            component="span">
                                            Upload Profile Image
                                        </Button>
                                        </label>
                                        {/* <Button
                                            variant="contained"
                                            color="default"
                                            className={classes.button}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            <input
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                style={{ display: 'none', }}
                                                //hidden
                                            />
                                            Upload Profile Image
                                            
                                        </Button> */}
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                    </Grid >
                                    <Grid item md={12} xs={12}>
                                    </Grid >
                                    <Grid item md={12} xs={12}>
                                    </Grid >

                                    <Grid container spacing={3} className={classes.buttonAlignment}>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                type="submit"
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="default"
                                                variant="outlined"
                                                //type="submit"
                                                onClick = {onHandleDisable}
                                            >
                                                {userDetails.accountStatus === 'disabled' ? 'Enable' : 'Disable'}
                                            </Button>
                                        </Grid >
                                        <Grid item>
                                            <Button
                                                color="secondary"
                                                variant="outlined"
                                                //type="submit"
                                                //onClick = {onHandleDelete}
                                                onClick={handleDeleButton}
                                            >
                                                Delete
                                            </Button>
                                        </Grid >
                                        <Grid item>
                                            <Button
                                                color="danger"
                                                variant="outlined"
                                                //type="submit"
                                                onClick = {() => window.history.back()}
                                            >
                                                Cancel
                                            </Button>


                                            <Popover
                                                //id={id}
                                                open={confirmDeletePopOver}
                                                //anchorEl={anchorEl}
                                                onClose={handleConfirmDeletePopOverClose}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                                }}
                                            >
                                                <Grid container justify="center" pb={2}>
                                                    <Grid  item xs={12}>
                                                        <Box my={2} textAlign="center">Are you sure to delete this employee ?</Box>
                                                    </Grid>
                                                    <Grid  item>
                                                        <Button
                                                            color="primary"
                                                            variant="outlined"
                                                            //type="submit"
                                                            onClick = {onHandleDelete}
                                                        >
                                                            Yes
                                                        </Button>
                                                    </Grid>
                                                    <Grid xs={2}/>
                                                    <Grid item>
                                                        <Button
                                                            color="danger"
                                                            variant="outlined"
                                                            //type="submit"
                                                            onClick = {handleConfirmDeletePopOverClose}
                                                        >
                                                            No
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}></Grid>
                                                </Grid> 
                                                <Box height="20px"/> 
                                            </Popover>
                                        </Grid >
                                    </Grid>
                                </Grid>

                                <Snackbar open={open} autoHideDuration={15000} onClose={handleNotificationClose}>
                                    {(() => {
                                        if (successPostMessage === true) {
                                            return <Alert onClose={handleNotificationClose} severity="success">Update success</Alert>
                                        // } else if (errorPostMessage === 'phone number already exists') {
                                        //     return <Alert onClose={handleNotificationClose} severity="error">{errorPostMessage}</Alert>
                                        // } else if (errorPostMessage === 403) {
                                        //     return <Alert onClose={handleNotificationClose} severity="error">Authentication expired! Please click <Link to='/' >here</Link> to go back to Login</Alert>
                                        // } else if (errorPostMessage === 'Email already exists') {
                                        //     return <Alert onClose={handleNotificationClose} severity="error">Email already exists</Alert>
                                        } else {
                                            return <Alert onClose={handleNotificationClose} severity="error">Update fail!</Alert>
                                        }
                                    })()}
                                </Snackbar>

                            </CardContent>
                        </Card>
                    </Container>
                </form>
            </div>
        </div>
    )
}