import React, { useState, useEffect, useContext, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  Snackbar,
  makeStyles
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
//import Page from '../components/Page';
//import Profile from './Profile';
import Navbar from '../../../layouts/DashboardLayout/NavBar';
import MuiAlert from '@material-ui/lab/Alert';
import AddJobForm from './AddJobForm';
import axios from 'axios';
import moment from 'moment';
//import { Alert } from '../_components'
// import { AuthContext } from "../App";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.dark,
//     minHeight: '100%',
//     paddingBottom: theme.spacing(3),
//     paddingTop: theme.spacing(3)
//   }
// }));

// const initialState = {
//     //candidates:[], 
//     isPosting: false,
//     hasError: false
// };
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.black,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
        
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        //flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '5rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
    table: {
        width: '100%',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    container: {
        width: '100%'
    },
    success: {
        marginTop: "8rem",
        width: '100%',
        '& > * + *': {
        marginTop: theme.spacing(2),
        },
    }
}));

function AddJobPage (props) {
    const classes = useStyles();
    const [successCode, setSuccessCode] = useState(false);
    const [errorCode, setErrorCode] = useState();
    const [open, setOpen] = useState(false);
    const [newJobResponseData, setNewJobResponseData] = useState({});
    let jobId, jobPosition;
    //console.log('localstorage ', localStorage.getItem("tokens"))
    // const { state: authState } = useContext(AuthContext);
    // const [state, dispatch] = useReducer(reducer, initialState);
    // let [jobs, setResponseJobs] = useState([]);
    //console.log('token ', 'Bearer ' + JSON.parse(localStorage.token))
    const newJob = (data) => {
        console.log('from Addjob Page', data);
        console.log('token ', 'Bearer ' + JSON.parse(localStorage.tokens))
        jobPosition = data.jobPosition;
        //axios.post('http://localhost:5001/sunshineenterpriseus/us-central1/api/jobs', {
        //let expired = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUwOGI0NzM0YjYxNmE0MWFhZmE5MmNlZTVjYzg3Yjc2MmRmNjRmYTIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVnUgTmd1eWVuIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3N1bnNoaW5lZW50ZXJwcmlzZXVzIiwiYXVkIjoic3Vuc2hpbmVlbnRlcnByaXNldXMiLCJhdXRoX3RpbWUiOjE2MDk5NjY4MDcsInVzZXJfaWQiOiI2MHpBMURVTlZIVUI3cmpzaHk1ZXRpZUFNa0YzIiwic3ViIjoiNjB6QTFEVU5WSFVCN3Jqc2h5NWV0aWVBTWtGMyIsImlhdCI6MTYwOTk2NjgwNywiZXhwIjoxNjA5OTcwNDA3LCJlbWFpbCI6Im5ndXllbmFuaGhvYW52dUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibmd1eWVuYW5oaG9hbnZ1QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.xHr1WG3cud7jO0pJlNOiChuEteD1BG47t1TB0BUrw7sZX8EAl-WKJ057JODnr-1wi5Xhetkk3H_Ls4LtJ2W28BrpJ-Puy681P6znSPAvxz3Xp3XgkK1SnuNqwaUL4BVjFpmwLKLNRo7UY14USP_2JfGeePURkez2v2z6C9kzbt4lIJcVwP4J6Kp6fHJAkVXPzECsc5n_mex9_ZxGJB3q6T13HDFCtyKzWmyWMI22sX-BgUXrx7aj_a__z7LueatKB30WXPd5SElMGci48P8aZbbGcoveIbyzmuQ-2Y4FjwfPn77J8UjEI58v_eoWxVM07lBp1kGT9JL1TLHza3wSUw";
        axios.post('https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/jobs', {
            city: data.city, clientName: data.clientName, employmentType: data.clientName, industry: data.industry, jobDescription: data.jobDescription, jobStatus: data.jobStatus, jobTitle: data.jobTitle, numberOfPositions: data.numberOfPositions, priority: data.priority, salary: data.salary, skillset: data.skillset, state: data.state,
            targetDate: moment(data.targetDate).format('MM/DD/YYYY'), workExperience: data.workExperience, zipCode: data.zipCode, jobPosition: data.jobPosition
        },
        {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
                //Authorization: 'Bearer ' + ' ' + expired
            }
        },
        )
        .then(res => {
            setSuccessCode(true);
            setOpen(true);
            setNewJobResponseData(res.data)
            //setTimeout(() => {console.log('transfer to new page')}, 3000)
            //window.location.href = '/app/jobs'
        })
        .catch(err => {
            console.error(err);
            setErrorCode(err.response.status);
            setOpen(true);
        });
        
    }
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <Container maxWidth={false}>
                    <AddJobForm newJob={newJob}/>
                </Container>

                <Snackbar open={open} autoHideDuration={15000} onClose={handleClose}>
                    {(() => {
                        if (successCode === true) {
                            return <Alert onClose={handleClose} severity="success">New job added! Click <Link to={{pathname:'/app/sendgrid', state:{data: newJobResponseData}}}>here </Link> to go to notification page.</Alert>
                        } else if (errorCode === 403) {
                            return <Alert onClose={handleClose} severity="error">Authentication expired! Please click <Link to='/' >here</Link> to go back to Login</Alert>
                        } else {
                            return <Alert onClose={handleClose} severity="error">Added job fail!</Alert>
                        }
                    })()}
                </Snackbar>

                {/* {successCode ? <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        New job added!
                    </Alert>
                </Snackbar> : <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        Added job fail!
                    </Alert>
                </Snackbar>} */}
            </div>
        </div>
        // <Page
        //     className={classes.root}
        //     title="AddJob"
        // >
            // <Container maxWidth="lg">
            //     <AddJobForm />
            // </Container>
        // </Page>
    );
};

export default AddJobPage;
