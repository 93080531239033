import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import FileIcon from '../../../icons/fileIcon.png';
import { useForm } from 'react-hook-form';
import Rating from '@material-ui/lab/Rating';
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import Navbar from '../../../layouts/DashboardLayout/NavBar';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Container,
    withStyles,
    makeStyles,
    FormControlLabel,
    Switch,
    Fade,
    Snackbar,
    FormLabel
} from '@material-ui/core';

const StyledTableCell  = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: '4rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
    container: {
        width: '100%'
    },
    titleBox: {
        display: 'flex',
        //justifyContent: 'center',
        marginBottom: '1rem',
        //marginTop: '2rem',
        backgroundColor: 'none'
    },
    left: {
        marginTop: '2rem',
        marginLeft: '1rem'
    },
    right:{
        marginTop: '2rem',
    },
    nameContainer: {
        marginTop: '0.5rem'
    },
    media: {
        margin: 'auto',
        width: 60,
        height: 60
    },
    fileIcon: {
        //width: '10px',
        height: '10px'
    },
    cardContent: {
        padding: '1rem'
    },
    textFieldWidth: {
        width: '85%'
    },
    typography: {
        paddingLeft: 55
    },
    table: {
        width: '94%',
        marginLeft: '55px'
    },
    notes: {
        paddingLeft: 50
    }
}));

const isHired = [
    {
        value: true,
        label: 'Yes'
    },
    {
        value: false,
        label: 'No'
    }
];

const isOnCall = [
    {
        value: true,
        label: 'Yes'
    },
    {
        value: false,
        label: 'No'
    }
];

const rating = [
    {
        value: '0',
        label: '0',
    },
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
]

function EditCandidate(props) {
    const { register, control, getValues, setValue, handleSubmit } = useForm();
    let candidateDetails = props.history.location.state.details;
    let token = localStorage.getItem('tokens');
    console.log('edit candidate number before edit ', props)
    let decodedToken = jwt_decode(token);
    //console.log('Decoded Token ', decodedToken);
    //console.log('TIME LEFT ', (decodedToken.exp * 1000 -Date.now())/(1000*60));
    const [successCode, setSuccessCode] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const classes = useStyles();
    const history = useHistory();
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const handleChange = () => {
        setChecked((prev) => !prev);
    };
    const handleRowClick = (jobId) => {
        //console.log('ROUTING TO JOBID ', jobId)
        history.push(`/app/jobs/${jobId}`);
    }
    function updateCandidate(data) {
        ////
        //let expired = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUwOGI0NzM0YjYxNmE0MWFhZmE5MmNlZTVjYzg3Yjc2MmRmNjRmYTIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVnUgTmd1eWVuIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3N1bnNoaW5lZW50ZXJwcmlzZXVzIiwiYXVkIjoic3Vuc2hpbmVlbnRlcnByaXNldXMiLCJhdXRoX3RpbWUiOjE2MDk5NjY4MDcsInVzZXJfaWQiOiI2MHpBMURVTlZIVUI3cmpzaHk1ZXRpZUFNa0YzIiwic3ViIjoiNjB6QTFEVU5WSFVCN3Jqc2h5NWV0aWVBTWtGMyIsImlhdCI6MTYwOTk2NjgwNywiZXhwIjoxNjA5OTcwNDA3LCJlbWFpbCI6Im5ndXllbmFuaGhvYW52dUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibmd1eWVuYW5oaG9hbnZ1QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.xHr1WG3cud7jO0pJlNOiChuEteD1BG47t1TB0BUrw7sZX8EAl-WKJ057JODnr-1wi5Xhetkk3H_Ls4LtJ2W28BrpJ-Puy681P6znSPAvxz3Xp3XgkK1SnuNqwaUL4BVjFpmwLKLNRo7UY14USP_2JfGeePURkez2v2z6C9kzbt4lIJcVwP4J6Kp6fHJAkVXPzECsc5n_mex9_ZxGJB3q6T13HDFCtyKzWmyWMI22sX-BgUXrx7aj_a__z7LueatKB30WXPd5SElMGci48P8aZbbGcoveIbyzmuQ-2Y4FjwfPn77J8UjEI58v_eoWxVM07lBp1kGT9JL1TLHza3wSUw";
        //let valid = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUwOGI0NzM0YjYxNmE0MWFhZmE5MmNlZTVjYzg3Yjc2MmRmNjRmYTIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVnUgTmd1eWVuIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3N1bnNoaW5lZW50ZXJwcmlzZXVzIiwiYXVkIjoic3Vuc2hpbmVlbnRlcnByaXNldXMiLCJhdXRoX3RpbWUiOjE2MTAwNDM1ODUsInVzZXJfaWQiOiI2MHpBMURVTlZIVUI3cmpzaHk1ZXRpZUFNa0YzIiwic3ViIjoiNjB6QTFEVU5WSFVCN3Jqc2h5NWV0aWVBTWtGMyIsImlhdCI6MTYxMDA0MzU4NSwiZXhwIjoxNjEwMDQ3MTg1LCJlbWFpbCI6Im5ndXllbmFuaGhvYW52dUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibmd1eWVuYW5oaG9hbnZ1QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.CJRrbrfaJQPtU6ThR4z-AjmoSu-YG8pjJenF85-VGtCVjJPDcnx94S-J2B--Mn_fdR5GVFL3FYoeHcHU3ZIG65kMNu9UH79-I25w2PtYpoMrp4xotHrDAqtcgZkI33tqMWy-eos5F_bAb8UmMJIIdpMumfA4K9EJQLhCw_eScTdxH-y71rvepw25Ypzi_LLyhlTfbmhyZgEc3Hww4kBBCYiGpBHDqwDmGjhp3dAeM2oJVvi7g0OIQXcZJn4Xvd156mG7a48KWSeGpoOpvH0diDZxDvhri_60ucFEvEh5PA0xvN1NnIIqOMa8yHqzYm9-RD263HFUdoKNUUSOKqG1tQ"
        ////
        //console.log('DATA SENT TO UPDATE ', data)
        // if(data.phoneNumber != undefined) {
        //     phoneNumber.split('(', ')', '-');
        //     console.log('PHONE NUMBER ', phoneNumber)
        // }  http://us-central1-sunshineenterpriseus.cloudfunctions.net/api  
        //axios.put(`http://localhost:5001/sunshineenterpriseus/us-central1/api/candidates/${candidateDetails.candidateId}`, data,
        axios.put(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/candidates/${candidateDetails.candidateId}`, data,
            {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
                    //Authorization: 'Bearer ' + ' ' + expired
                }
            },
            )
            .then(res => {
                //console.log('resdata ', res.data);
                setSuccessCode(true);
                setOpen(true);
                //setResponseCandidate(res.data);
                // dispatch({
                //     type: 'FETCH_JOBS_SUCCESS',
                //     payload: res.data
                // })
                //history.push(`/app/candidates/${candidateId}`);
                console.log('Post success');
                //setCandidateDetails({...candidateDetails, ...data});
                //history.push(`/app/candidates/${candidateDetails.candidateId}`)
                // window.location.reload();
                //window.location.href = '/app/jobs'
                // return (
                // <Redirect to={`/app/candidates/${candidateDetails.candidateId}`} /> )
            })
            .catch(err => {
                console.error(err);
                console.log('ERROR IS ', err.response);
                console.log('deep ', err.response.status)
                console.log('type of status ', typeof(err.response.status))
                setSuccessCode(false);
                //setErrorCode('auth/id-token-expired');
                setErrorCode(err.response.status);
                setOpen(true);
                // dispatch({
                //     type: 'FETCH_JOBS_FAILURE',
                // });
            });
    }
    const onSubmit = (data) => {
        console.log('UPDATE CANDIDATE ', data);
        updateCandidate(data);
    }
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Container maxWidth={false}>
                        <Box display="flex">
                            <Box display="flex" width="100%" className={classes.titleBox}>
                                <div className={classes.left}>
                                    <CardMedia className={classes.media}>
                                        <Avatar className={classes.purple, classes.media}>
                                            <Typography variant="h5" fontWeight="bold">{candidateDetails.lastName.slice(0,1)}</Typography>
                                        </Avatar>
                                    </CardMedia>
                                    <Box component="fieldset" mb={3} borderColor="transparent">
                                        <Rating
                                            size="small"
                                            name="simple-controlled"
                                            readOnly
                                            value={candidateDetails.rating}
                                            // onChange={(event, newValue) => {
                                            //     setValue(newValue);
                                            // }}
                                        />
                                    </Box>
                                </div>
                                <div className={classes.right}>
                                    <Box display="flex" alignItems="center" className={classes.nameContainer}>
                                        <Typography variant="h4" fontWeight="bold">{candidateDetails.firstName} {candidateDetails.lastName}</Typography>
                                        <IconButton color="primary" aria-label="Download" href={candidateDetails.resume} className={classes.fileIcon}>
                                            {/* <img src={FileIcon} alt='file icon' style={styles.image}/>    */}
                                        </IconButton>
                                        {/* <Typography>{candidateDetails.resume}</Typography> */}
                                    </Box>
                                </div>
                            </Box>
                        </Box>
                        <Card>
                            <CardContent className={classes.cardContent} >
                                <Grid container spacing={1} alignItems="center" justify="flex-start">
                                    <Grid item md={12} xs={12} order={1}>
                                        <Typography component="div" align="left" className={classes.typography}>
                                            <Box fontWeight="fontWeightBold">
                                                Candidate Info
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Candidate Name"
                                            placeholder="Candidate Name"
                                            name="name"
                                            readOnly
                                            //variant="outlined"
                                            //inputRef={register}
                                            value={candidateDetails.firstName + ' ' + candidateDetails.lastName}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label="Candidate ID"
                                            name="candidateId"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            value={candidateDetails.candidateId}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Phone Number"
                                            InputLabelProps={{ shrink: true }}
                                            name="phoneNumber"
                                            readOnly
                                            //variant="outlined"
                                            inputRef={register}
                                            //defaultValue={'(' + (candidateDetails.phoneNumber.slice(0,3)) + ') ' + candidateDetails.phoneNumber.slice(3,6) + '-' + candidateDetails.phoneNumber.slice(6,10)} 
                                            defaultValue={candidateDetails.phoneNumber}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            //floatingLabelFixed={true}
                                            label={"Email"}
                                            placeholder="Email"
                                            name="email"
                                            readOnly
                                            inputRef={register}
                                            defaultValue={candidateDetails.email}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12} >
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold" paddingTop={2}>
                                                Address Info
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Street"
                                            name="street"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={candidateDetails.street}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="City"
                                            name="city"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={candidateDetails.city}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label="Zip Code"
                                            name="zipCode"
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={candidateDetails.zipCode}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="State"
                                            name="state"
                                            InputLabelProps={{ shrink: true }}
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={candidateDetails.state}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12} >
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold" paddingTop={2}>
                                                Educational Details
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={12} xs={12}>
                                    </Grid>
                                    <Grid item md={12} xs={12} >
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold" paddingTop={2}>
                                                Experience Details
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={12} xs={12}></Grid>
                                    <Grid item md={12} xs={12} >
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold" paddingTop={2}>
                                                Professional Details
                                            </Box>
                                        </Typography>
                                    </Grid >
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Skill set"
                                            name="skillSet"
                                            InputLabelProps={{ shrink: true }}
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={candidateDetails.skillSet}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="On Call"
                                            name="isOnCall"
                                            required
                                            select
                                            SelectProps={{ native: true }}
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={candidateDetails.isOnCall === true ? 'Yes' : 'No'}
                                        >
                                            {isOnCall.map((option) => (
                                                <option
                                                key={option.value}
                                                value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Work Experience"
                                            name="workExperience"
                                            InputLabelProps={{ shrink: true }}
                                            //required
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={candidateDetails.workExperience}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Position"
                                            name="position"
                                            //required
                                            //variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            inputRef={register}
                                            defaultValue={candidateDetails.position}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Type"
                                            name="type"
                                            //required
                                            //variant="outlined"
                                            // inputRef={register}
                                            value={candidateDetails.type}
                                        />
                                    </Grid>         
                                    <Grid item md={12} xs={12}>
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold" paddingTop={2} paddingBottom={2}>
                                                Applied Jobs
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Table size="small" className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell >
                                                        Job Title
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Job ID
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Applied On
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        City
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Assigned Recruiter
                                                    </StyledTableCell >
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {candidateDetails.appliedJobs.map(job => 
                                                (
                                                <TableRow 
                                                    hover
                                                    key={job.jobId}
                                                    // //selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                                                    // // button key='job' component={Link} to='/app/job/:jobId'
                                                    onClick={()=> handleRowClick(job.jobId)}
                                                >
                                                    <TableCell>
                                                        {job.jobTitle}
                                                    </TableCell>
                                                    <TableCell>
                                                        {job.jobId}
                                                    </TableCell>
                                                    <TableCell>
                                                        {job.appliedOn}
                                                    </TableCell>
                                                    <TableCell>
                                                        {job.city}
                                                    </TableCell>
                                                    <TableCell>
                                                        {job.assignedRecruiter}
                                                    </TableCell>
                                                    
                                                </TableRow>
                                                )
                                            )}
                                            </TableBody>

                                        </Table>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography component="div" align="left" className={classes.typography} >
                                            <Box fontWeight="fontWeightBold" paddingTop={2} paddingBottom={2}>
                                                Notes
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Table size="small" className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell >
                                                        Recruiter
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Recruiter ID
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Created On
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Interview
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Job Interviewed
                                                    </StyledTableCell >
                                                    <StyledTableCell >
                                                        Note
                                                    </StyledTableCell >
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {candidateDetails.notes.map(note => 
                                                (
                                                <TableRow 
                                                    hover
                                                    key={note.createdOn}
                                                    // //selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                                                    // // button key='job' component={Link} to='/app/job/:jobId'
                                                    // onClick={()=> handleRowClick(candidate.candidateId)}
                                                >
                                                    <TableCell>
                                                        {note.recruiter}
                                                    </TableCell>
                                                    <TableCell>
                                                        {note.recruiterId}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(note.createdOn).format('MM-DD-YYYY') }
                                                    </TableCell>
                                                    <TableCell>
                                                        {note.interview === true ? 'Yes' : 'No'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {note.interviewedJob}
                                                    </TableCell>
                                                    <TableCell>
                                                        {note.notes}
                                                    </TableCell>
                                                    
                                                </TableRow>
                                                )
                                            )}
                                            </TableBody>
                                        </Table>
                                    </Grid> 
                                    <Grid item md={12} xs={12}>
                                    </Grid >
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Updated By"
                                            name="updatedBy"
                                            InputLabelProps={{ shrink: true }}
                                            //required
                                            //variant="outlined"
                                            // inputRef={register}
                                            //inputRef={register}
                                            value={candidateDetails.updatedBy}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Rating"
                                            name="rating"
                                            type="number"
                                            id="rating"
                                            InputLabelProps={{ shrink: true }}
                                            SelectProps={{ native: true }}
                                            select
                                            //variant="outlined"
                                            defaultValue={candidateDetails.rating}
                                            inputRef={register}
                                            
                                            //value={candidateDetails.rating}
                                        >
                                            {rating.map((option) => (
                                                <option
                                                key={option.value}
                                                value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Hired"
                                            name="isHired"
                                            required
                                            select
                                            SelectProps={{ native: true }}
                                            //variant="outlined"
                                            inputRef={register}
                                            defaultValue={candidateDetails.isHired === true ? 'Yes' : 'No'}
                                        >
                                            {isHired.map((option) => (
                                                <option
                                                key={option.value}
                                                value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            className={classes.textFieldWidth}
                                            fullWidth
                                            label="Status"
                                            name="isHired"
                                            required
                                            //variant="outlined"
                                            // inputRef={register}
                                            value={candidateDetails.isHired === true ? 'Yes' : 'No'}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}></Grid >
                                    <Grid item md={12} xs={12}></Grid >

                                    <Grid item md={12} xs={12}>
                                        <FormLabel align="left" className={classes.typography} component="legend">Did Candidate Interview ?</FormLabel>
                                        {/* <FormControlLabel control={<Switch color="primary" checked={checked} onChange={handleChange} />} /> */}
                                    </Grid>

                                    <Grid item container direction="column" alignItems="flex-start" md={6} xs={4} >
                                        
                                        {/* <FormGroup>
                                            {candidateDetails.appliedJobs.map(job => (
                                                <FormControlLabel control={<Checkbox  inputRef={register} checked={checkBoxState.jobId} onChange={handleChange} name={job.jobId}/>} label={job.jobTitle}/>
                                            ))}
                                        </FormGroup> */}


                                        <FormControlLabel className={classes.typography} control={<Switch color="primary" checked={checked} onChange={handleChange} />} />
                                        <Grid className={classes.typography}>
                                            <Fade in={checked} >
                                                <TextField
                                                    fullWidth
                                                    label="Job Applied"
                                                    name="interviewedJob"
                                                    //required
                                                    select
                                                    SelectProps={{ native: true }}
                                                    //variant="outlined"
                                                    inputRef={register}
                                                    defaultValue=''
                                                >
                                                    <option>

                                                    </option>
                                                    {candidateDetails.appliedJobs.map((job) => (
                                                        <option
                                                        key={job.jobTitle}
                                                        value={job.jobId}
                                                        >
                                                            {job.jobTitle}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Fade>
                                        </Grid>
                                        
                                    </Grid >
                                    <Grid item md={12} xs={1}>
                                    </Grid >

                                    <Grid item md={12} xs={12}>
                                        <Box width="97%" className={classes.notes}>
                                        <TextField          
                                            fullWidth
                                            label="Notes"
                                            name="notes"
                                            multiline
                                            rows={3}
                                            variant="outlined"
                                            inputRef={register}
                                            //value={candidateDetails.note}
                                        />
                                        </Box>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                    </Grid >
                                    <Grid item md={12} xs={12}>
                                    </Grid >
                                    <Grid item md={12} xs={12}>
                                    </Grid >

                                    <Grid container spacing={3} className={classes.notes}>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="default"
                                                variant="contained"
                                                //type="submit"
                                                onClick = {() => window.history.back()}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid >
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Container>
                    
                    <Snackbar open={open} autoHideDuration={15000} onClose={handleClose}>
                        {(() => {
                            if (successCode === true) {
                                return <Alert onClose={handleClose} severity="success">Candidate Updated!</Alert>
                            } else if (errorCode === 403) {
                                return <Alert onClose={handleClose} severity="error">Authentication expired! Please click <Link to='/' >here</Link> to go back to Login</Alert>
                            } else {
                                return <Alert onClose={handleClose} severity="error">Update fail!</Alert>
                            }
                        })()}
                    </Snackbar>
    


                    {/* {(errorCode === 'auth/id-token-expired') ? <Snackbar open={open} autoHideDuration={15000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error">
                            Authentication expired! Please click <Link to='/' >here</Link> to go back to Login
                        </Alert>
                    </Snackbar> : (successCode == true) ? <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success">
                            Candidate Updated!
                        </Alert>
                    </Snackbar> : <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error">
                            Update fail!
                        </Alert>
                    </Snackbar>
                    } */}


                    {/* {successCode ? <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success">
                            Candidate Updated!
                        </Alert>
                    </Snackbar> : <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error">
                            Update fail!
                        </Alert>
                    </Snackbar>} */}
                </form>
            </div>
        </div>
    )
}

export default EditCandidate


