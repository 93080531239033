import React, { useState, useEffect, useRef, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { 
    Container,
    Box,
    Card,
    Grid,
    LinearProgress
    // Table,
    // TableBody,
    // TableCell,
    // TableHead,
    // Button,
    // TableRow 
} from '@material-ui/core';
import { AuthContext } from '../../../contexts/AuthContext';
import Navbar from '../../../layouts/DashboardLayout/NavBar';
import JobToolbar from '../JobToolbar';
import axios from 'axios';
import { DataGrid, GridOverlay, ColDef } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
        
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        //flex: '1 1 auto',
        overflow: 'hidden',
        width: '100%',
        paddingTop: '5rem',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
    container: {
        marginBottom: 40
    },
    table: {
        width: '100%',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    gridWrapper: {
        width: '100%'
    },
}));

function JobList() {
    const classes = useStyles();
    const [loading, setLoading] = useState(true)
    //const { userAuth } = useContext(AuthContext);
    const [jobs, setJobs] = useState([]);
    const [filter, setFilter] = useState('')
    const history = useHistory();
    const handleRowClick = (job) => {
        history.push(`/app/jobs/${job.data.jobId}`);
    }  

    const searchJob = (jobTitle) => {
        setFilter(jobTitle)
    }
    let arr = [];
    const columns = [
        { field: 'jobTitle', width: 470, renderHeader: () => (<strong style={{fontSize: 17}}>Job Title</strong>)},
        { field: 'city', width: 200, renderHeader: () => (<strong style={{fontSize: 17}}>City</strong>)},
        { field: 'clientName', width: 280, renderHeader: () => (<strong style={{fontSize: 17}}>Client Name</strong>)},
        { field: 'createdOn', type: 'date', width: 190, renderHeader: () => (<strong style={{fontSize: 17}}>Created On</strong>)},
        { field: 'jobStatus', width: 150, renderHeader: () => (<strong style={{fontSize: 17}}>Job Status</strong>)},
        { field: 'numberOfPositions', width: 95, renderHeader: () => (<strong style={{fontSize: 17}}>Needed</strong>)},
        { field: 'appliedCandidates', width: 125, renderHeader: () => (<strong style={{fontSize: 17}}>Candidates</strong>)},
        { field: 'submittedCandidates', width:100, renderHeader: () => (<strong style={{fontSize: 17}}>Hired</strong>)},
    ];
    const [data, setData] = useState({columns: columns, rows:[]})
    
    useEffect(() => {
        
        axios.get('https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/alljobs', {
            headers: {
                Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
            }
        })
        .then(res => {
            let counter = 0;
            res.data.forEach(item => {
                arr.push({jobTitle:item.jobTitle, city:item.city, clientName: item.clientName, createdOn:moment(item.createdOn).format('M/DD/YYYY'), jobStatus:item.jobStatus, numberOfPositions:item.numberOfPositions, appliedCandidates:item.appliedCandidates.length, submittedCandidates:item.submittedCandidates.length, jobId:item.jobId, id:counter++})
            })
            setJobs(arr);
            setData({columns: columns, rows: [...arr]})
            setLoading(false);
        })
        .catch(err => {
            console.error(err);
        });
    }, [jobs.length])

    function CustomLoadingOverlay() {
        return (
          <GridOverlay>
            <div style={{ position: 'absolute', top: 10, width: '100%' }}>
              <LinearProgress />
            </div>
          </GridOverlay>
        );
    }
    
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <Container maxWidth={false} className={classes.container}>
                    <Grid container spacing={3} className={classes.gridWrapper}>
                        <Grid item md={12} xs={12}>
                            <JobToolbar className={classes.toolbar} data={data} searchJob={searchJob}/>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Card>
                                <Box height={700}>
                                    <DataGrid  
                                        {...data} 
                                        loading ={loading}
                                        pageSize={50} 
                                        onRowSelected={handleRowClick} 
                                        components={{
                                            loadingOverlay: CustomLoadingOverlay,
                                        }} 
                                        filterModel={{
                                            items: [{ columnField: 'jobTitle', operatorValue: 'contains', value: filter },],
                                        }} 
                                    />
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item md={12} xs={12} />
                    </Grid>
                </Container>
            </div>
        </div>
    )
}

export default JobList
