import React, { useState } from 'react';
import {useForm, Controller} from 'react-hook-form';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Snackbar,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import InputMask from "react-input-mask";

// function required(displayName) {
//     return function validateRequired(value) {
//       // console.log("VALIDATING: ", displayName, value);
//       return value !== null || `${displayName} is required.`;
//     };
// }

const useStyles = makeStyles((theme) => ({
    root: {},
}));

// function useOnMount(handler) {
//     return React.useEffect(handler, []);
// }

const AddJobForm = ({newContact}) => {
    const {register, control, getValues, setValue , handleSubmit} = useForm();
    const [errorMessage, setErrorMessage] = useState();
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const onSubmit = (data) => {
        console.log('mobile length is ', data.mobile.trim().length)
        if (data.mobile.trim().length !== 12) {
            setErrorMessage(prevState => 'Invalid phone number');
            setOpen(true);
            return;
        }
        newContact(data);
        
        console.log('from addcontactform ', data)
    }
    const handleClose = (event, reason) => {
        setOpen(false);
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Card>
                    <CardHeader subheader="The information can be edited" title="New Contact" />
                    <Divider />
                    <CardContent>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12} >
                                    <Typography component="div" align="left" className={classes.typography} >
                                        <Box fontWeight="fontWeightBold">
                                            Contact Information
                                        </Box>
                                    </Typography>
                                </Grid >
                                <Grid item md={6} xs={12}>
                                    <TextField 
                                        fullWidth
                                        label='Contact Name'
                                        name='name'
                                        required
                                        variant='outlined'
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField 
                                        fullWidth
                                        label='Company'
                                        name='company'
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField 
                                        fullWidth
                                        label="Title"
                                        name="title"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField 
                                        fullWidth
                                        label="Description"
                                        name="description"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <TextField 
                                        fullWidth
                                        label="Personal Email"
                                        name="personalEmail"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>  
                                <Grid item md={3} xs={12}>
                                    <TextField 
                                        fullWidth
                                        label="Work Email"
                                        name="workEmail"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid> 
                                {/* <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Mobile"
                                        name="mobile"
                                        required
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>   */}
                                <Grid item md={4} xs={12}>
                                <InputMask
                                    mask="+19999999999"
                                    //value={inputCustomNumber}
                                    disabled={false}
                                    maskChar=" "
                                    //inputRef={register}
                                    name="Mobile"
                                    //onChange={handleCustomNumberChange}
                                    >
                                    {() => <TextField 
                                        label="add custom number"
                                        fullWidth
                                        name="customNumber"
                                        variant="outlined"
                                        name="mobile"
                                        label="Mobile"
                                        required
                                        inputRef={register}
                                        // InputProps={{
                                        //     endAdornment: (
                                        //         <InputAdornment position="start">
                                        //             <IconButton type="submit" color="primary">
                                        //             <SvgIcon
                                        //                 fontSize="small"
                                        //                 color="action"
                                        //             >
                                        //                 <AddIcon />
                                        //             </SvgIcon>
                                        //             </IconButton>
                                        //         </InputAdornment>
                                        //     )
                                        // }}
                                    />}
                                </InputMask>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Work Phone"
                                        name="workPhone"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Home Phone"
                                        name="homePhone"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12} >
                                    <Typography component="div" align="left" className={classes.typography} >
                                        <Box fontWeight="fontWeightBold">
                                            Address Information
                                        </Box>
                                    </Typography>
                                </Grid >
                                <Grid item md={3} xs={12}>
                                    <TextField 
                                        fullWidth
                                        label="Street"
                                        name="street"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="City"
                                        name="city"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}></Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="State"
                                        name="state"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Zip Code"
                                        name="zipCode"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                
                                
                            </Grid>
                        {/* </MuiPickersUtilsProvider> */}
                    </CardContent>

                    {/* <Divider /> */}

                    <Box display="flex" justifyContent="flex-end" p={2}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Add Contact
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="default"
                                    variant="contained"
                                    //type="submit"
                                    onClick = {() => window.history.back()}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>

                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            {(() => {
                                if (errorMessage === `Invalid phone number`) {
                                    return <Alert onClose={handleClose} severity="error">Phone number format is incorrect the correct format is +1XXXXXXXXXX</Alert>
                                } 
                            })()}
                        </Snackbar>

                    </Box>
                </Card>
            </form>
        </div>
    )
}

export default AddJobForm;