import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import {Link} from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import EditIcon from '@material-ui/icons/Edit';
import SmsIcon from '@material-ui/icons/Sms';
import PhoneIcon from '@material-ui/icons/Phone';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from "react-router-dom";
import {useForm, Controller} from 'react-hook-form';
import { DataGrid } from '@material-ui/data-grid';
import FileIcon from '../../../icons/fileIcon.png';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Popover,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Container,
    withStyles,
    makeStyles,
    CardMedia,
    Snackbar,
    CardHeader,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Alert, AlertTitle } from '@material-ui/lab';

const options= [
    'Remove content',
]
const ITEM_HEIGHT = 48;
const useStyles = makeStyles((theme) => ({
    buttonMargin: {
        marginRight: '2rem'
    }
}));

function ContentManagementComponent({contactDetails}) {
    const history = useHistory();
    const classes = useStyles();
    const [anchorWebMenu, setAnchorWebMenu] = useState(null);
    const [menuPosition, setMenuPosition] = useState({vertical: 0 ,horizontal: 0});
    
    const CustomButton = withStyles({
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 16,
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: '#adeaed',
            borderColor: '#adeaed',
            fontFamily: [
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
              backgroundColor: '#0069d9',
              borderColor: '#0062cc',
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
              backgroundColor: '#0062cc',
              borderColor: '#005cbf',
            },
            '&:focus': {
              boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        },  
    })(Button);
    const handleWebMenuClick = (event) => {
        console.log(event)
        //setMenuPosition({vertical: event.clientX,horizontal: event.clientY})
        setAnchorWebMenu(event.currentTarget);
    };
    const handleUserMenuClose = () => {
        setAnchorWebMenu(null);
    };
    return (
        <Box display="flex">
            <Card>
                <CardContent align="left" >
                    <Box fontWeight="fontWeightBold">Web Content management</Box>    
                </CardContent>
                <Divider/>
                <CardContent>
                    <Typography align="left" variant="h2" gutterBottom>Web Content management</Typography>
                    <Box mt={3} />
                    <Typography align="left">Add, edit, and remove web content.</Typography>
                    <Box mt={7} align="left" display="flex" >
                        <Box width="100%">
                            <CustomButton className={classes.buttonMargin}>Add content</CustomButton>
                            <CustomButton>Edit content</CustomButton>
                        </Box>
                        <Divider orientation="vertical" variant="inset" flexItem />
                        <Box flexShrink={0}>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleWebMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorWebMenu}
                                //anchorOrigin={{ vertical: menuPosition.vertical, horizontal: menuPosition.horizontal}}
                                keepMounted
                                open={anchorWebMenu}
                                onClose={handleUserMenuClose}
                            >
                                {options.map((option) => (
                                <MenuItem key={option} selected={option === 'Reset Password'} onClick={handleUserMenuClose}>
                                    {option}
                                </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default ContentManagementComponent;