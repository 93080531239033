import React from 'react';
import {Link} from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Drawer,
    List,
    Typography,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Card,
    CardContent,
    Box,
    CssBaseline,
} from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WorkIcon from '@material-ui/icons/Work';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TextsmsIcon from '@material-ui/icons/Textsms';
import PeopleIcon from '@material-ui/icons/People';
import SecurityIcon from '@material-ui/icons/Security';
import PersonIcon from '@material-ui/icons/Person';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AppIcon from '../../../icons/fullIcon.png';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, deepPurple, yellow } from '@material-ui/core/colors';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    avatar: {
        display: 'flex',
        '& > *': {
         margin: theme.spacing(1),
        },
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        background: 'blue'
        //marginTop: '80px'
    },
    drawerPaper: {
        width: drawerWidth,
        background: '#e6e6e6'
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    image: {
        paddingLeft: '15px',
        width: '120px'
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    signoutButton: {
        marginRight: theme.spacing(2),
      },
}));

const NavBar = () => {
    const classes = useStyles();
    const role = localStorage.getItem('role');
    console.log('ROLE IS ', role)
    return (
        //<h1> Hello </h1>
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar >
                    <Box display='flex' flexGrow={1}>
                        <Typography variant="h6" noWrap>
                            Sunshine Enterprise
                        </Typography>
                    </Box>
                    <Box>
                        <ExitToAppIcon />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <img src={AppIcon} alt='sunshine enterprise icon' className={classes.image} />
            
                <Divider />
                <Card style={{backgroundColor: "#e6e6e6"}}>
                    <CardContent>
                        <Box 
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                        >
                            {/* <Avatar className={classes.purple}>{localStorage.user.split(' ')[0].slice(1,2)}</Avatar> */}
                            <Avatar className={classes.purple}>{localStorage.getItem('firstInitial')}</Avatar>
                            <Typography
                                className={classes.profilename}
                                color="textPrimary"
                                variant="subtitle1"
                            >{localStorage.getItem('name')}
                            </Typography>
                            <Typography
                                
                                color="textPrimary"
                                variant="subtitle1"
                            >{localStorage.getItem('role')}
                            </Typography>
                        </Box>

                    </CardContent>
                </Card>
                    
                
            
                <List>
                    <ListItem button key='Dashboard' component={Link} to='/app/dashboard'>
                        <ListItemIcon>  <DashboardIcon /> </ListItemIcon>
                        <ListItemText primary='Dashboard' />
                    </ListItem>
                    <ListItem button key='Jobs' component={Link} to='/app/jobs'>
                        <ListItemIcon>  <WorkIcon /> </ListItemIcon>
                        <ListItemText primary='Jobs' />
                    </ListItem>
                    <ListItem button key='Candidates' component={Link} to='/app/candidates'>
                        <ListItemIcon>  <PeopleIcon /> </ListItemIcon>
                        <ListItemText primary='Candidates' />
                    </ListItem>
        
                    <ListItem button key='Assignments' component={Link} to='/app/assignments'>
                        <ListItemIcon>  <AssignmentIcon /> </ListItemIcon>
                        <ListItemText primary='Assignments' />
                    </ListItem>
                    <ListItem button key='Clients' component={Link} to='/app/clients'>
                        <ListItemIcon>  <PermContactCalendarIcon /> </ListItemIcon>
                        <ListItemText primary='Clients' />
                    </ListItem>
                    <ListItem button key='TextSMS' component={Link} to='/app/sms'>
                        <ListItemIcon>  <TextsmsIcon /> </ListItemIcon>
                        <ListItemText primary='Text SMS' />
                    </ListItem>
                    {/* <ListItem button key='Analytics' component={Link} to='/app/analytics'>
                        <ListItemIcon>  <AssessmentIcon /> </ListItemIcon>
                        <ListItemText primary='Analytics' />
                    </ListItem> */}
                    {/* <ListItem button key='Account' component={Link} to='/app/account'>
                        <ListItemIcon>  <PersonIcon /> </ListItemIcon>
                        <ListItemText primary='Account' />
                    </ListItem> */}
                    <ListItem button key='Settings' component={Link} to='/app/settings'>
                        <ListItemIcon>  <SettingsIcon /> </ListItemIcon>
                        <ListItemText primary='Settings' />
                    </ListItem>
                    {role === 'admin' ? 
                    <ListItem button key='Settings' component={Link} to='/app/admin'>
                        <ListItemIcon>  <SecurityIcon /> </ListItemIcon>
                        <ListItemText primary='Admin' />
                    </ListItem> :
                    <></>}
                </List>
        
            </Drawer>
        </div>
    )
}

export default NavBar;