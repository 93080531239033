import React, { useState } from 'react';
import {useForm, Controller} from 'react-hook-form';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


const priority = [
    {
        value: 1,
        label: '1'
    },
    {
        value: 2,
        label: '2'
    },
    {
        value: 3,
        label: '3'
    },
    {
        value: 4,
        label: '4'
    }
];

const positions = [
    {
        value: 'CNA',
        label: 'CNA'
    },
    {
        value: 'RN',
        label: 'RN'
    },
    {
        value: 'LPN',
        label: 'LPN'
    },
    {
        value: 'APRNs',
        label: 'APRNs'
    },
    {
        value: 'Others',
        label: 'Others'
    }
];

const jobStatus = [
    {
        value: 'In-progress',
        label: 'In Progress'
    },
    {
        value: 'Filled',
        label: 'Filled'
    },
    {
        value: 'Declined',
        label: 'Declined'
    },
    {
        value: 'On-hold',
        label: 'On Hold'
    },
]

const employmentType = [
    {
        value: 'Full Time',
        label: 'Full Time'
    },
    {
        value: 'Part Time',
        label: 'Part Time'
    },
    {
        value: 'contract',
        label: 'Contract'
    },
    {
        value: 'temp-to-perm',
        label: 'Temp-to-perm'
    },
    {
        value: 'On Call',
        label: 'On Call'
    },
]

function required(displayName) {
    return function validateRequired(value) {
      // console.log("VALIDATING: ", displayName, value);
      return value !== null || `${displayName} is required.`;
    };
}

const useStyles = makeStyles((theme) => ({
    root: {},
}));

function useOnMount(handler) {
    return React.useEffect(handler, []);
}

const AddJobForm = ({newJob}) => {
    const {register, control, getValues, setValue , handleSubmit} = useForm();
    const [selectedDate, setDate] = useState(new Date());
    //
    const [text, setText] = useState('');
    //
    const classes = useStyles();

    const onSubmit = (data) => {
        newJob(data);
        console.log('from addjobform ', data)
    }
    const handleDateChange = date => {
        console.log("targetDate CHANGED: ", date);
        setDate(date)
        setValue("targetDate", date);
    };

    const values = getValues();

    useOnMount(() => {
        console.log("useEffect register");
        register(
          { name: "targetDate", type: "text" },
          { name: "text", type: "text"},
         { validate: required("Target date") }
        );
    });

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Card>
                    <CardHeader subheader="The information can be edited" title="New Job" />
                    <Divider />
                    <CardContent>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextField 
                                        fullWidth
                                        label='Job Title'
                                        name='jobTitle'
                                        required
                                        variant='outlined'
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField 
                                        fullWidth
                                        label='Number of Positions'
                                        name='numberOfPositions'
                                        type="number"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField 
                                        fullWidth
                                        label="Employment Type"
                                        name="employmentType"
                                        required
                                        select
                                        SelectProps={{ native: true }}
                                        variant="outlined"
                                        inputRef={register}
                                    >
                                        {employmentType.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField 
                                        fullWidth
                                        label="Client Name"
                                        name="clientName"
                                        required
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Display Priority"
                                        helperText="1 is highest priority, job with higher priority will display at the top"
                                        name="priority"
                                        type="number"
                                        required
                                        select
                                        SelectProps={{ native: true }}
                                        variant="outlined"
                                        inputRef={register}
                                    >
                                        {priority.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                    ))}
                                    </TextField>
                                </Grid>  
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Job Status"
                                        name="jobStatus"
                                        required
                                        select
                                        SelectProps={{ native: true }}
                                        variant="outlined"
                                        inputRef={register}
                                    >
                                        {jobStatus.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid> 
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Work Experience"
                                        name="workExperience"
                                        //required
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>  
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Pay/Salary"
                                        name="salary"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Inudstry"
                                        name="industry"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <KeyboardDatePicker
                                        id="targetDate"
                                        name="targetDate"
                                        label="Target Date"
                                        format="MM/dd/yyyy"
                                        disablePast
                                        margin="normal"
                                        disableToolbar
                                        value={values.targetDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date"
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Skill Set"
                                        name="skillset"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Job Position"
                                        helperText="help match with the list of candidate has the same position in database"
                                        name="jobPosition"
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}></Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="City"
                                        name="city"
                                        required
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="State"
                                        name="state"
                                        required
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Zip Code"
                                        name="zipCode"
                                        required
                                        variant="outlined"
                                        inputRef={register}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Controller name="jobDescription" control={control} render ={({ onChange, value, name }) => (
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            config={{height:'300px'}}
                                            onInit={ editor => {
                                                // You can store the "editor" and use when it is needed.
                                                console.log( 'Editor is ready to use!', editor );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                value = editor.getData();
                                                onChange(editor.getData());
                                            } }
                                        />
                                    )}/>
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </CardContent>

                    {/* <Divider /> */}

                    <Box display="flex" justifyContent="flex-end" p={2}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Add Job
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="default"
                                    variant="contained"
                                    //type="submit"
                                    onClick = {() => window.history.back()}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </form>
        </div>
    )
}

export default AddJobForm;