import react from 'react';
//import PropTypes from 'prop-types';
//import clsx from 'clsx';
import {Link} from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  addContactButton: {
    marginRight: theme.spacing(1)
  },
  sendGroupSMSButton: {
    marginRight: theme.spacing(2)
  }
}));

const ContactToolbar = ({ className, data, searchContact, ...rest }) => {
  let contactList = data;
  console.log('data from contact toolbar ', data.columns)
  const classes = useStyles();
  //console.log('from job toolbar', data.rows)
  const handleChange = event => {
    console.log('value ', event.target.value);
    searchContact(event.target.value);
  }
  return (
    <div
    //   className={clsx(classes.root, className)}
    //   {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Link to={{pathname:`/app/sendgroupsms/contacts`, data: {contactList: data}}} style={{ textDecoration: 'none' }}>
          <Button
            className={classes.sendGroupSMSButton}
            color="primary"
            variant="contained"
            //button key='addJob' //component={Link} to='/app/sendgroupsms/contacts'
          >
            Send Group SMS
          </Button>
        </Link>
        
        <Button
          color="primary"
          variant="contained"
          button key='addJob' component={Link} to='/app/add/contacts'
        >
          Add Contact
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                onChange = {handleChange}
                //value = {props.value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search contact"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

// JobsToolbar.propTypes = {
//   className: PropTypes.string
// };

export default ContactToolbar;
