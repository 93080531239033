import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
//import { Container } from '@material-ui/core';
import { AuthContext } from '../../../contexts/AuthContext';
import Navbar from '../../../layouts/DashboardLayout/NavBar';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 40,
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 40
        }
    },
}));

function DashboardPage() {
    let token = localStorage.getItem('tokens');
    //console.log(token);
    const classes = useStyles();
    const { authTokens, setAuthTokens } = useContext(AuthContext);
    //console.log('authContext ', userAuth)
    //console.log('userAuth from Dashboard', authTokens)
    //console.log('settoken ', setAuthTokens)
    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.wrapper}>
                <h1>Dashboard Page Under Construction</h1>
            </div>
        </div>
    )
}

export default DashboardPage
