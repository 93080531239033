import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import {Link, Redirect} from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import EditIcon from '@material-ui/icons/Edit';
import SmsIcon from '@material-ui/icons/Sms';
import PhoneIcon from '@material-ui/icons/Phone';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from "react-router-dom";
import {useForm, Controller} from 'react-hook-form';
import { DataGrid } from '@material-ui/data-grid';
import FileIcon from '../../../icons/fileIcon.png';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Popover,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Container,
    withStyles,
    makeStyles,
    CardMedia,
    Snackbar,
    CardHeader,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Alert, AlertTitle } from '@material-ui/lab';
//import UserList from './';

const options= [
    'Reset Password',
    'Delete User'
]
const roles = [
    {
        value: '',
        label: ''
    },
    {
        value: 'admin',
        label: 'Admin'
    },
    {
        value: 'recruiter',
        label: 'Recruiter'
    },
    {
        value: 'marketing',
        label: 'Marketing'
    },
    {
        value: 'web developer',
        label: 'Web developer'
    },
    {
        value: 'other',
        label: 'Other'
    },
]
const types = [
    {
        value: '',
        label: ''
    },
    {
        value: 'nurse',
        label: 'Nurse'
    },
    {
        value: 'teacher',
        label: 'Teacher'
    },
    {
        value: 'none',
        label: 'None'
    },
]
const positions = [
    {
        value: '',
        label: ''
    },
    {
        value: 'CNA',
        label: 'CNA'
    },
    {
        value: 'RN',
        label: 'RN'
    },
    {
        value: 'LPN',
        label: 'LPN'
    },
    {
        value: 'APRNs',
        label: 'APRNs'
    },
    {
        value: 'Teacher',
        label: 'Teacher'
    },
    {
        value: 'Substitute Teacher',
        label: 'Substitute Teacher'
    },
    {
        value: 'others',
        label: 'Others'
    }
];
const ITEM_HEIGHT = 48;
const useStyles = makeStyles((theme) => ({
    buttonMargin: {
        marginRight: '2rem'
    }
    // root: {
    //     display: 'flex',
    // },
    // avatar: {
    //     display: 'flex',
    //     '& > *': {
    //      margin: theme.spacing(1),
    //     },
    // },
    // titleBox: {
    //     display: 'flex',
    //     marginBottom: '1rem',
    //     //marginTop: '2rem',
    //     backgroundColor: 'none'
    // },
    // left: {
    //     marginTop: '2rem',
    //     marginLeft: '1rem'
    // },
    // right:{
    //     marginTop: '2rem',
    //     marginLeft: '2rem',
    //     display: 'block'
    // },
    // media: {
    //     margin: 'auto',
    //     width: 70,
    //     height: 70
    // },
    // workIcon: {
    //     width: 60,
    // },
    // cardContent: {
    //     paddingTop: '2rem',
    // },
    // table: {
    //     backgroundColor: '#e6e6e6',
    //     marginBottom: '1rem',
    //     width: '94%',
    //     marginLeft: '55px'
    // },
    // tableRow: {
    //     border: 'none'
    // },
    // tableCellHead: {
    //     width: '25%',
    //     paddingLeft: '2rem'
    // },
    // summaryTableCell: {
    //     backgroundColor: 'white',
    //     width: '25%',
    //     paddingLeft: '3rem'
    // },
    // tableCellData: {
    //     backgroundColor: 'white',
    //     width: '25%',
    // },
    // purple: {
    //     color: theme.palette.getContrastText(deepPurple[500]),
    //     backgroundColor: deepPurple[500],
    // },
    // textFieldWidth: {
    //     width: '80%'
    // },
    // typography: {
    //     paddingLeft: 65
    // },
    // editor: {
    //     //width: '94%'
    //     marginLeft: 50,
    //     marginRight: 30
    // },
    // contentStyles:{},
    // tableHead: {
    //     fontWeight: 'bold',
    // }
}));

function UserManagementComponent({contactDetails}) {
    const history = useHistory();
    const classes = useStyles();
    const [anchorUserMenu, setAnchorUserMenu] = useState(null);
    //
    const [openUserList, setOpenUserList] = useState(false);
    const [userList, setUserList] = useState();
    const [successPostMessage, setSuccessPostMessage] = useState(false);
    const [errorPostMessage, setErrorPostMessage] = useState();
    const [open, setOpen] = useState(false);
    const [addUserFormOpen, setAddUserFormOpen] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [type, setType] = useState('');
    const [role, setRole] = useState('');
    const [position, setPosition] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');
    ///////////
    //const [drawerState, setDrawerState] = useState(false);
    ///////////
    const [errorMessage, setErrorMessage] = useState([]);

    const CustomButton = withStyles({
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 16,
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: '#adeaed',
            borderColor: '#adeaed',
            fontFamily: [
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
              backgroundColor: '#0069d9',
              borderColor: '#0062cc',
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
              backgroundColor: '#0062cc',
              borderColor: '#005cbf',
            },
            '&:focus': {
              boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        },  
    })(Button);

    const isPhoneNumber = (number) => {
        const regEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        console.log('number is type', typeof(number))
        if (number.match(regEx)) return true;
        else return false;
    }
    const isEmail = (email) => {
        const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.match(regEx)) return true;
        else return false;
    }
    // const isDob = (dob) => {
    //     console.log('dob ', dob)
    //     const regEx = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/;
    //     if (dob.match(regEx)) return true;
    //     else return false;
    // }
    const handleUserMenuClick = (event) => {
        setAnchorUserMenu(event.currentTarget);
    };
    const handleUserMenuClose = () => {
        setAnchorUserMenu(null);
    };
    const handleUserMenuOptionClick = (e) => {
        // console.log(e.target.innerText)
        console.log('CLICK')
        if (e.target.innerText === 'Reset Password') {
            console.log('click reset')
            //setDrawerState(true)
        } else {
            console.log('click Delete')
        }
    };
    const openAddUserForm = () => {
        setAddUserFormOpen(true);
    }
    const handleEditUser = () => {
        axios.get('https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/employees', {
            headers: {
                    Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
                }, 
        })
        .then(res => {
            console.log('get employee data success', res.data)
            console.log(res.data)
            setUserList([...res.data])
            
            setOpenUserList(true);
            // if (res.data.error) {
            //     setSuccessPostMessage(false);
            //     setErrorPostMessage('Email already exists')
            //     setOpen(true)
            // } else {
            //     setSuccessPostMessage(true);
            //     setOpen(true)
            // }
            //console.log('user list ', userList)
        })
        .catch(err => {
            console.log('message for error ', err)
            // setSuccessPostMessage(false)
            // setOpen(true)
            // console.log('error message ', err.response)
            //console.error(err.response.message);
        });
        
        //console.log('OPEN USER LIST')
    }
    const handleClose = () => {
        setAddUserFormOpen(false);
        setOpenUserList(false);
    };
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const onUserNameClick = () => {
        console.log('user ')
        console.log('CLICK')
    }
    const handleAddUser = (e) => {
        e.preventDefault();
        let newEmployee = {
            firstName, lastName, email, phone, dob:'', type, role, position, address:{street, city, state, zipcode, country: ''}
        }
        console.log('DATA INPUT ', newEmployee)
        let errors = [];
        if (!isEmail(email)){
            //console.log('error in email')
            errors.push('Invalid email')
            //setErrorMessage(prevState => prevState.push('Invalid email'))
            //console.log('error code for personal email ', errorCode)
            //return;
        }
        if (!isPhoneNumber(phone)){
            errors.push('Invalid phone number')
            //console.log('error in phone number')
            //setErrorMessage([...errorMessage, 'Invalid phone number'])
            //console.log('error code for personal email ', errorCode)
            //return;
        } 
        if (newEmployee.firstName === ''){
            errors.push('First name must not be empty')
        }
        if (newEmployee.lastName === ''){
            errors.push('Last name must not be empty')
        }
        if (newEmployee.role === ''){
            errors.push('Role must not be empty')
        }
        if (newEmployee.type === ''){
            errors.push('Type must not be empty')
        }
        if (newEmployee.position === ''){
            errors.push('Position must not be empty')
        }
        // if (!isDob(dob)){
        //     console.log('dob is ', newEmployee.dob)
        //     errors.push('Invalid dob')
        //     //console.log('error in dob')
        //     //setErrorMessage([...errorMessage, 'Invalid dob'])
        //     //console.log('error code for personal email ', errorCode)
        //     //return;
        // }
        if(errors.length > 0){
            setErrorMessage(errors);
            console.log('there error and return')
            return;
        } 
        console.log('no error continue')
        //axios.post('http://localhost:5001/sunshineenterpriseus/us-central1/api/employee', newEmployee, {
        axios.post('https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/employee', newEmployee, {
            headers: {
                    Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
                }, 
        })
        .then(res => {
            console.log('success add employee', res.data)
            if (res.data.error) {
                setSuccessPostMessage(false);
                setErrorPostMessage('Email already exists')
                setOpen(true)
            } else {
                setSuccessPostMessage(true);
                setOpen(true)
            }
        })
        .catch(err => {
            console.log('message for error ', err)
            setSuccessPostMessage(false)
            //setErrorPostMessage(err.response.status)
            setOpen(true)
            console.log('error message ', err.response)
            //console.error(err.response.message);
        });
        //console.log('error message ', errorMessage)
    }
    return (
        <Box display="flex">
            <Card>
                <CardContent align="left" >
                    <Box fontWeight="fontWeightBold">User management</Box>    
                </CardContent>
                <Divider/>
                <CardContent>
                    <Typography align="left" variant="h2" gutterBottom>User management</Typography>
                    <Box mt={3} />
                    <Typography align="left">Add, edit, remove user accounts, and reset passwords.</Typography>
                    <Box mt={7} align="left" display="flex" >
                        <Box width="100%">
                            <CustomButton className={classes.buttonMargin} onClick={openAddUserForm}>Add user</CustomButton>
                                <Dialog open={addUserFormOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">Add new user</DialogTitle>
                                    <DialogContent>
                                    <DialogContentText>
                                        Fill out following information about new user.
                                    </DialogContentText>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                autoFocus
                                                // margin="dense"
                                                error = {errorMessage.includes('First name must not be empty') ? true : false}
                                                helperText= {errorMessage.includes('First name must not be empty') && 'First name must not be empty'}
                                                name="firstName"
                                                onChange={e => setFirstName(e.target.value)}
                                                label="First name"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                autoFocus
                                                // margin="dense"
                                                error = {errorMessage.includes('Last name must not be empty') ? true : false}
                                                helperText= {errorMessage.includes('Last name must not be empty') && 'Last name must not be empty'}
                                                name="lastName"
                                                onChange={e => setLastName(e.target.value)}
                                                label="Last name"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                autoFocus
                                                error = {errorMessage.includes('Invalid email') ? true : false}
                                                helperText= {errorMessage.includes('Invalid email') && 'invalid email'}
                                                margin="dense"
                                                name="email"
                                                required
                                                onChange={e => setEmail(e.target.value)}
                                                label="Email Address"
                                                type="email"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                autoFocus
                                                error = {errorMessage.includes('Invalid phone number') ? true : false}
                                                helperText= {errorMessage.includes('Invalid phone number') && 'invalid phone format'}
                                                margin="dense"
                                                name="phone"
                                                onChange={e => setPhone(e.target.value)}
                                                label="Phone number"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        {/* <Grid item md={6} xs={12}>
                                            <TextField
                                                autoFocus
                                                error = {errorMessage.includes('Invalid dob') ? true : false}
                                                //helperText= {errorMessage.includes('Invalid dob') && 'Invalid dob'}
                                                helperText= "ERROR MESSAGE"
                                                margin="dense"
                                                name="dob"
                                                onChange={e => setDob(e.target.value)}
                                                label="DOB"
                                                fullWidth
                                                //required
                                            />
                                        </Grid> */}
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                error = {errorMessage.includes('Role must not be empty') ? true : false}
                                                helperText= {errorMessage.includes('Role must not be empty') && 'Employee must have a role'}
                                                label="Role"
                                                name="role"
                                                required
                                                select
                                                SelectProps={{ native: true }}
                                                variant="outlined"
                                                // defaultValue="admin"
                                                onChange={e => setRole(e.target.value)}
                                            >
                                                {roles.map((role) => (
                                                    <option
                                                        key={role.value}
                                                        value={role.value}
                                                    >
                                                        {role.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                error = {errorMessage.includes('Type must not be empty') ? true : false}
                                                helperText= {errorMessage.includes('Type must not be empty') && 'Employee must have a type'}
                                                label="Type"
                                                name="type"
                                                //required
                                                select
                                                SelectProps={{ native: true }}
                                                variant="outlined"
                                                //defaultValue="nurse"
                                                onChange={e => setType(e.target.value)}
                                            >
                                                {types.map((type) => (
                                                    <option
                                                        key={type.value}
                                                        value={type.value}
                                                    >
                                                        {type.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                error = {errorMessage.includes('Position must not be empty') ? true : false}
                                                helperText= {errorMessage.includes('Position must not be empty') && 'Employee must have a position'}
                                                label="Position"
                                                name="position"
                                                //required
                                                select
                                                SelectProps={{ native: true }}
                                                variant="outlined"
                                                //defaultValue="others"
                                                onChange={e => setPosition(e.target.value)}
                                            >
                                                {positions.map((position) => (
                                                    <option
                                                        key={position.value}
                                                        value={position.value}
                                                    >
                                                        {position.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                name="street"
                                                onChange={e => setStreet(e.target.value)}
                                                label="Street"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                name="city"
                                                onChange={e => setCity(e.target.value)}
                                                label="City"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                name="state"
                                                onChange={e => setState(e.target.value)}
                                                label="State"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                name="zipcode"
                                                onChange={e => setZipcode(e.target.value)}
                                                label="Zip code"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleAddUser} color="primary">
                                        Add User
                                    </Button>
                                    </DialogActions>
                                </Dialog>
                            <CustomButton onClick={handleEditUser}>Edit user</CustomButton>
                                <Dialog
                                    open={openUserList}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">User List</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Select user to edit
                                        </DialogContentText>
                                        
                                            {userList ? userList.map(user => (
                                                <Link style={{ textDecoration: 'none', color: 'black' }} to={{pathname:`/app/admin/usermanagement/edit/${user.userId}`, state: {details: user}}}>
                                                <ListItem key={user.userId} button >
                                                <ListItemAvatar>
                                                <Avatar
                                                    // alt={`Avatar n°${value + 1}`}
                                                    // src={`/static/images/avatar/${value + 1}.jpg`}
                                                />
                                                </ListItemAvatar>
                                                <ListItemText id={user.userId} primary={`${user.firstName + ' ' + user.lastName}`} secondary={`${user.email}`} />
                                                </ListItem>
                                                </Link>
                                            )) : <></>}
                                        
                                        
                                    </DialogContent>
                                    <DialogActions>
                                    {/* <Button onClick={handleClose} color="primary">
                                        Disagree
                                    </Button>
                                    <Button onClick={handleClose} color="primary" autoFocus>
                                        Agree
                                    </Button> */}
                                    </DialogActions>
                                </Dialog>
                        </Box>
                        <Divider orientation="vertical" variant="inset" flexItem />
                        <Box flexShrink={0}>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleUserMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorUserMenu}
                                //anchorOrigin={{ vertical: menuPosition.vertical, horizontal: menuPosition.horizontal}}
                                keepMounted
                                open={anchorUserMenu}
                                onClose={handleUserMenuClose}
                            >
                                {options.map((option) => (
                                <MenuItem 
                                    key={option} 
                                    //selected={option === 'Reset Password'} 
                                    //onClick={handleUserMenuClose}
                                    onClick={handleUserMenuOptionClick}
                                >
                                    {option}
                                </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Box>
                    <Snackbar open={open} autoHideDuration={15000} onClose={handleNotificationClose}>
                        {(() => {
                            if (successPostMessage === true) {
                                return <Alert onClose={handleNotificationClose} severity="success">New employee added!</Alert>
                            } else if (errorPostMessage === 'phone number already exists') {
                                return <Alert onClose={handleNotificationClose} severity="error">{errorPostMessage}</Alert>
                            } else if (errorPostMessage === 403) {
                                return <Alert onClose={handleNotificationClose} severity="error">Authentication expired! Please click <Link to='/' >here</Link> to go back to Login</Alert>
                            } else if (errorPostMessage === 'Email already exists') {
                                return <Alert onClose={handleNotificationClose} severity="error">Email already exists</Alert>
                            } else {
                                return <Alert onClose={handleNotificationClose} severity="error">Added employee fail!</Alert>
                            }
                        })()}
                    </Snackbar>
                    {/* <UserList drawerState={drawerState}/> */}
                </CardContent>
                
            </Card>
        </Box>
    )
}

export default UserManagementComponent;

