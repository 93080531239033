import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import {Link} from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import EditIcon from '@material-ui/icons/Edit';
import SmsIcon from '@material-ui/icons/Sms';
import PhoneIcon from '@material-ui/icons/Phone';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from "react-router-dom";
import {useForm, Controller} from 'react-hook-form';
import { DataGrid } from '@material-ui/data-grid';
import FileIcon from '../../../icons/fileIcon.png';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    Grid,
    IconButton,
    Popover,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Container,
    withStyles,
    makeStyles,
    CardMedia,
    Snackbar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    avatar: {
        display: 'flex',
        '& > *': {
         margin: theme.spacing(1),
        },
    },
    titleBox: {
        display: 'flex',
        marginBottom: '1rem',
        //marginTop: '2rem',
        backgroundColor: 'none'
    },
    left: {
        marginTop: '2rem',
        marginLeft: '1rem'
    },
    right:{
        marginTop: '2rem',
        marginLeft: '2rem',
        display: 'block'
    },
    media: {
        margin: 'auto',
        width: 70,
        height: 70
    },
    workIcon: {
        width: 60,
    },
    cardContent: {
        paddingTop: '2rem',
    },
    table: {
        backgroundColor: '#e6e6e6',
        marginBottom: '1rem',
        width: '94%',
        marginLeft: '55px'
    },
    tableRow: {
        border: 'none'
    },
    tableCellHead: {
        width: '25%',
        paddingLeft: '2rem'
    },
    summaryTableCell: {
        backgroundColor: 'white',
        width: '25%',
        paddingLeft: '3rem'
    },
    tableCellData: {
        backgroundColor: 'white',
        width: '25%',
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    textFieldWidth: {
        width: '80%'
    },
    typography: {
        paddingLeft: 65
    },
    editor: {
        //width: '94%'
        marginLeft: 50,
        marginRight: 30
    },
    contentStyles:{},
    tableHead: {
        fontWeight: 'bold',
    }
}));

function ContactDetailComponent({contactDetails}) {
    //console.log('from Contact Detail component ++++', contactDetails.smsHistory)
    const [openDialog, setDialogOpen] = useState(false);
    const [openNotification, setNotificationOpen] = useState(false);
    const [smsMessage, setSmsMessage] = useState("");
    //const [displaySMSHistory, setDisplaySMSHistory] = useState([]);
    const [successCode, setSuccessCode] = useState(false);
    const [errorCode, setErrorCode] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [longSMS, setLongSMS] = useState('');
    const [deleteButton, setDeleteButton] = useState(false);
    const [deleteMessages, setDeleteMessages] = useState([]);
    const id = "text";
    const open = Boolean(anchorEl);
    const columns = [
        { field: 'sent', width: 500, renderHeader: () => (<strong style={{fontSize: 17}}><Button>SMS Sent</Button></strong>), renderCell: (params) => (
            <Typography onMouseEnter={handlePopoverOpen}>{params.value}</Typography>
        )},
        { field: 'received', width: 500, renderHeader: () => (<Typography noWrap style={{fontSize: 17}}>SMS Received</Typography>)},
        { field: 'createdOn', type: 'dateTime', width: 200, renderHeader: () => (<strong style={{fontSize: 17}}>Time</strong>)},
        { field: 'sentBy', width: 150, renderHeader: () => (<strong style={{fontSize: 17}}>Sent By</strong>)},
        // { field: 'action', width: 150,renderCell: (params) => ( deleteButton ?
        //     <Button onClick={deleteMessage}>Delete Message</Button> : <></>
        // )},
    ];
    let rows = [];
    //console.log('ROWS IS ', rows)
    let counter = 0;
    contactDetails.smsHistory.forEach(row => {
        row.id = counter;
        row.createdOn = moment(row.createdOn).format('YYYY/M/D, H:mm:ss');
        rows.unshift(row)
        //rows.push(row)
        counter++;
        
    })
 

    console.log('rows need sorted', rows);
    function deleteMessage(e){
        console.log('contactId ',contactDetails.contactId)
        

        //axios.delete(`http://localhost:5001/sunshineenterpriseus/us-central1/api/contacts/${contactDetails.contactId}/sms/delete`,
        axios.delete(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/contacts/${contactDetails.contactId}/sms/delete`,
            {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
                    //Authorization: 'Bearer ' + ' ' + expired
                },
                data:{
                    messagesToDelete: deleteMessages
                }
            })
            .then(res => {
                console.log('resdata ', res.data);
                setSuccessCode('success delete messages');
                setNotificationOpen(true);
                console.log('new success code ', successCode)
                // console.log('Post success');
                // console.log('HERE')
                // setSuccessCode(true);
                // setNotificationOpen(true);
                //setCandidateDetails({...candidateDetails, ...data});
                //history.push(`/app/contacts/${contactDetails.contactId}`)
                //window.location.reload();
                //window.location.href = '/app/contacts/${contactDetails.contactId}'
                // return (
                // <Redirect to={`/app/candidates/${candidateDetails.candidateId}`} /> )
            })
            .catch(err => {
                console.error(err.response);
                setSuccessCode(false);
                setErrorCode(err.response.status);
                // console.log('ERROR CODE ', errorCode);
                // console.log('SUCCESS CODE ', successCode)
                setNotificationOpen(true);
                // dispatch({
                //     type: 'FETCH_JOBS_FAILURE',
                // });
            });
    }
    let smsData = {columns: columns, rows: rows}
    //const [data, setData] = useState({columns: columns, rows: rows})
    //let name = contactDetails.name;
    //const {register, control, getValues, setValue , handleSubmit} = useForm();
    const history = useHistory();
    const classes = useStyles();
    //console.log('from jobdetail component', jobDetails.appliedCandidates)
    // const handleNameClick = (candidateId) => {
    //     console.log('candidateId', candidateId)
    //     history.push(`/app/candidates/${candidateId}`);
    // }
    // const handleCellClick = (data) => {
    //     // STH HERE
    //     console.log('from cell', data);
    //     setLongSMS(data.value)
    //     setAnchorEl(true);
    // }
    function handlePopoverOpen (data) {
        if(data.value){
            if(data.value.length > 45){
                setLongSMS(data.value)
                setAnchorEl(true);
            } else {
                setAnchorEl(null);
            }
        } else {
            setAnchorEl(null);
        }
    }
    const handleChange = (event) => {
        console.log(event.target.value);
        setSmsMessage(event.target.value);
    };
    const handleClickOpen = () => {
        setDialogOpen(true);
    };
    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    let selectedMessage = [];
    const handleRowClick = (rowSelected) => {
        ///
        // if (contact.isSelected === true){
        //     setNumberList([...contactList, contact.data.mobile])
        //     console.log('new list ', contactList)
        // } else {
        //     setNumberList(contactList.filter(item => item != contact.data.mobile))
        // }
        //setDeleteMessages

        //
        console.log('Row select ', rowSelected)
        if (rowSelected.isSelected === true){
            setDeleteMessages([...deleteMessages, rowSelected.data.messageId])
            console.log('new list of deletemessage ', deleteMessages)
            //setDeleteButton(true)
        } else {
            console.log('deselect')
            setDeleteMessages(deleteMessages.filter(message => message !== rowSelected.data.messageId))
            if (deleteMessages.length === 0){
                setDeleteButton(false)
            }
            //setDeleteButton(false)
        }
    }
        // while(rowSelected.isSelected === true)
        // setDeleteButton(true);
     
    const onSendSMS = () => {
        console.log('submit data', smsMessage);
        let data = {
            phoneNumber : contactDetails.mobile,
            body: smsMessage,
            contactId: contactDetails.contactId
        }
        let expired = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUwOGI0NzM0YjYxNmE0MWFhZmE5MmNlZTVjYzg3Yjc2MmRmNjRmYTIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVnUgTmd1eWVuIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3N1bnNoaW5lZW50ZXJwcmlzZXVzIiwiYXVkIjoic3Vuc2hpbmVlbnRlcnByaXNldXMiLCJhdXRoX3RpbWUiOjE2MDk5NjY4MDcsInVzZXJfaWQiOiI2MHpBMURVTlZIVUI3cmpzaHk1ZXRpZUFNa0YzIiwic3ViIjoiNjB6QTFEVU5WSFVCN3Jqc2h5NWV0aWVBTWtGMyIsImlhdCI6MTYwOTk2NjgwNywiZXhwIjoxNjA5OTcwNDA3LCJlbWFpbCI6Im5ndXllbmFuaGhvYW52dUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibmd1eWVuYW5oaG9hbnZ1QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.xHr1WG3cud7jO0pJlNOiChuEteD1BG47t1TB0BUrw7sZX8EAl-WKJ057JODnr-1wi5Xhetkk3H_Ls4LtJ2W28BrpJ-Puy681P6znSPAvxz3Xp3XgkK1SnuNqwaUL4BVjFpmwLKLNRo7UY14USP_2JfGeePURkez2v2z6C9kzbt4lIJcVwP4J6Kp6fHJAkVXPzECsc5n_mex9_ZxGJB3q6T13HDFCtyKzWmyWMI22sX-BgUXrx7aj_a__z7LueatKB30WXPd5SElMGci48P8aZbbGcoveIbyzmuQ-2Y4FjwfPn77J8UjEI58v_eoWxVM07lBp1kGT9JL1TLHza3wSUw";
        //axios.post(`http://localhost:5001/sunshineenterpriseus/us-central1/api/contacts/sms/send`, data,
        axios.post(`https://us-central1-sunshineenterpriseus.cloudfunctions.net/api/contacts/sms/send`, data,
            {
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(localStorage.tokens)
                    //Authorization: 'Bearer ' + ' ' + expired
                }
            },
            )
            .then(res => {
                console.log('resdata ', res.data);
                console.log('Post success');
                console.log('HERE')
                setSuccessCode(true);
                setNotificationOpen(true);
                //setCandidateDetails({...candidateDetails, ...data});
                //history.push(`/app/job/${jobDetails.jobId}`)
                // window.location.reload();
                //window.location.href = '/app/jobs'
                // return (
                // <Redirect to={`/app/candidates/${candidateDetails.candidateId}`} /> )
            })
            .catch(err => {
                //console.error(err.response);
                setSuccessCode(false);
                setErrorCode(err.response.status);
                console.log('ERROR CODE ', errorCode);
                console.log('SUCCESS CODE ', successCode)
                setNotificationOpen(true);
                // dispatch({
                //     type: 'FETCH_JOBS_FAILURE',
                // });
            });
    };
    return (
        <Container maxWidth={false}>
            <Box display="flex">
                <Box display="flex" width="100%" className={classes.titleBox}>
                    <div className={classes.left}>
                        <CardMedia className={classes.media}><Avatar className={classes.purple, classes.media}><PhoneIcon fontSize="large"/></Avatar></CardMedia>
                    </div>
                    <div className={classes.right}>
                        {/* <Typography>{name}</Typography> */}
                        <Typography variant="h3" fontWeight="bold" align="left">{contactDetails.name}</Typography>
                        {/* <Typography align="left">{contactDetails.contactStatus}</Typography> */}
                    </div>
                </Box>
                <Box display="flex" flexShrink={1} p={3} mr={3} alignItems="center">
                    <Button flexShrink={1} onClick={handleClickOpen}>
                        <SmsIcon color="primary" fontSize="large" ></SmsIcon>
                    </Button>
                    <Link flexShrink={0} to={{pathname:`/app/contacts/${contactDetails.contactId}/edit`, state: {details: contactDetails}}}>
                        <EditIcon color="primary" fontSize="large" ></EditIcon>
                    </Link>
                </Box>
            </Box>
            <Card>
                <CardContent className={classes.cardContent}>
                    <Grid container spacing={1} alignItems="center" justify="flex-start">
                        <Grid item md={12} xs={12} order={1}>
                            <Typography component="div" align="left" className={classes.typography}>
                                <Box fontWeight="fontWeightBold">
                                    Contact Info
                                </Box>
                            </Typography>
                        </Grid >
                        <Grid item md={6} xs={12} >
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Contact Name"
                                placeholder="Contact Name"
                                name="name"
                                readOnly
                                value={contactDetails.name}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Contact ID"
                                name="contactId"
                                readOnly
                                //variant="outlined"
                                // inputRef={register}
                                value={contactDetails.contactId}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Mobile"
                                name="mobile"
                                readOnly
                                //variant="outlined"
                                // inputRef={register}
                                value={contactDetails.mobile} 
                            />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                //floatingLabelFixed={true}
                                label={"Home Phone"}
                                placeholder="Home Phone"
                                name="homePhone"
                                readOnly
                                // inputRef={register}
                                value={contactDetails.homePhone}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                //floatingLabelFixed={true}
                                label={"Work Phone"}
                                placeholder="Work Phone"
                                name="workPhone"
                                readOnly
                                // inputRef={register}
                                value={contactDetails.workPhone}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                //floatingLabelFixed={true}
                                label={"Company"}
                                placeholder="Company"
                                name="company"
                                readOnly
                                // inputRef={register}
                                value={contactDetails.company}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                //floatingLabelFixed={true}
                                label={"Title"}
                                placeholder="Title"
                                name="title"
                                readOnly
                                // inputRef={register}
                                value={contactDetails.title}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                //floatingLabelFixed={true}
                                label={"Personal Email"}
                                placeholder="Personal Email"
                                name="personalEmail"
                                readOnly
                                // inputRef={register}
                                value={contactDetails.personalEmail}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                //floatingLabelFixed={true}
                                label={"Work Email"}
                                placeholder="Work Email"
                                name="workEmail"
                                readOnly
                                // inputRef={register}
                                value={contactDetails.workEmail}
                            />
                        </Grid>
                        <Grid item md={12} xs={12} >
                            <Typography component="div" align="left" className={classes.typography} >
                                <Box fontWeight="fontWeightBold" paddingTop={2}>
                                    Address Info
                                </Box>
                            </Typography>
                        </Grid >
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Street"
                                name="street"
                                InputLabelProps={{ shrink: true }}
                                //required
                                //variant="outlined"
                                //inputRef={register}
                                value={contactDetails.address.street}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="City"
                                name="city"
                                InputLabelProps={{ shrink: true }}
                                //required
                                //variant="outlined"
                                //inputRef={register}
                                value={contactDetails.address.city}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="State"
                                name="state"
                                InputLabelProps={{ shrink: true }}
                                //required
                                //variant="outlined"
                                //inputRef={register}
                                value={contactDetails.address.state}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className={classes.textFieldWidth}
                                fullWidth
                                label="Zip Code"
                                name="zipCode"
                                InputLabelProps={{ shrink: true }}
                                //required
                                //variant="outlined"
                                //inputRef={register}
                                value={contactDetails.address.zipCode}
                            />
                        </Grid>
                        <Grid item md={12} xs={12} >
                            <Typography component="div" align="left" className={classes.typography} >
                                <Box fontWeight="fontWeightBold" paddingTop={2}>
                                    SMS History
                                </Box>
                            </Typography>
                        </Grid >
                        <Grid item md={12} xs={12}>
                            {/* <Box height={700}>
                                <DataGrid  checkboxSelection  {...data} pageSize={50} 
                                />
                            </Box> */}
                            <Box height={400} ml={7} mr={6}>
                                <DataGrid  checkboxSelection  {...smsData} pageSize={50} onRowSelected={handleRowClick} onCellHover={handlePopoverOpen} />
                                
                            </Box>
                            <Box mt={3} mr={6} display="flex" justifyContent="flex-end">
                                {deleteMessages.length>0 ? <Button variant="contained" color="secondary" onClick={deleteMessage}>Delete Message</Button> : <></>}
                            </Box>
                            
                            {/* <Table size="small" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>
                                            SMS Sent
                                        </TableCell>
                                        <TableCell className={classes.tableHead}>
                                            SMS Receive
                                        </TableCell>
                                        <TableCell className={classes.tableHead}>
                                            Time
                                        </TableCell>
                                        <TableCell className={classes.tableHead}>
                                            Sent By
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {rows.map(row => (
                                    <TableRow hover key={row.id}>
                                        <TableCell>
                                            {row.sent}
                                        </TableCell>
                                        <TableCell>
                                            {row.received}
                                        </TableCell>
                                        <TableCell>
                                            {row.createdOn}
                                        </TableCell>
                                        <TableCell>
                                            {row.sentBy}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table> */}
                        </Grid >

                        

                        <Grid item md={12} xs={12}>
                            
                        </Grid>

                        <Grid item md={12} xs={12}>
                            
                        </Grid>
                        
                        <Popover
                            id="text"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                            }}
                        >
                            <Typography >{longSMS}</Typography>
                        </Popover>
                       
                        <Dialog open={openDialog} onClose={openDialog} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">SMS</DialogTitle>
                            <DialogContent>
                            <DialogContentText>
                                Please enter the message you want to send to this contact.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                onChange={handleChange}
                                margin="dense"
                                id="message"
                                label="Message"
                                //type="mes"
                                fullWidth
                            />
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleDialogClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleDialogClose, onSendSMS} color="primary">
                                Send
                            </Button>
                            </DialogActions>
                        </Dialog>      
                        <Grid item md={12} xs={12}></Grid >
                        <Grid item md={12} xs={12}></Grid >
                        
                    </Grid>
                </CardContent>

                <Snackbar open={openNotification} autoHideDuration={15000} onClose={handleNotificationClose}>
                    {(() => {
                        if (successCode === true) {
                            return <Alert onClose={handleNotificationClose} severity="success">Message sent.</Alert>
                        } else if (successCode === 'success delete messages') {
                            return <Alert onClose={handleNotificationClose} severity="success">Message deleted!</Alert>
                        } else if (errorCode === 403) {
                            return <Alert onClose={handleNotificationClose} severity="error">Authentication expired! Please click <Link to='/' >here</Link> to go back to Login</Alert>
                        } else {
                            return <Alert onClose={handleNotificationClose} severity="error">Message sent fail!</Alert>
                        }
                    })()}
                </Snackbar>
            </Card>
        </Container>
    )
}

export default ContactDetailComponent;