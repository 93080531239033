import React from 'react'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles,
    Container,
    Typography
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

export default function SendgridContact(props) {
    console.log('from sendgrid from ', props.data)
    console.log('contact s list ', props.data.returnList)
    //const classes = useStyles();
    // Define data for contact grid
    const columns = [
        { field: 'name', width: 270, renderHeader: () => (<strong style={{fontSize: 17}}>Name</strong>)},
        { field: 'phone', width: 200, renderHeader: () => (<strong style={{fontSize: 17}}>Mobile</strong>)},
        { field: 'email', width: 200, renderHeader: () => (<strong style={{fontSize: 17}}>Mobile</strong>)},
        { field: 'position', width: 200, renderHeader: () => (<strong style={{fontSize: 17}}>Mobile</strong>)},
    ];
    let rows = props.data.returnList.map(contact => (
        {
            name : contact.first_name + ' ' + contact.last_name,
            phone: contact.phone_number,
            email: contact.email,
            positoin: contact.custom_fields.position,
            id: contact.id
        }

    ))
    let data = {columns:columns, rows:rows};
    console.log('data is ', data)

    return (
        <Container  maxWidth={false}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12} >
                    <Typography variant="h4">Contact List</Typography>
                    <DataGrid  checkboxSelection  {...data} pageSize={50}
                    // onRowSelected={handleRowClick}
                        //filterModel={{ items: [{ columnField: 'mobile', operatorValue: 'contains', value: filterNumber}, { columnField: 'name', operatorValue: 'contains', value:filter} ]}} 
                    />
                </Grid>
                <Grid item md={6} xs={12}> PART dsfdsfsdfsdfsdfsdfB</Grid>
            </Grid>   
        </Container>
    )
}
